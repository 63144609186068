import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Row } from 'saladsoft-primitive';
import styled from 'styled-components';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import LanguageSelector from '../LanguageSelector';
import mainRoutes from 'routes/mainRoutes';
import { getRouteConfig } from 'libs/helper/utils';

const MobileGnb = () => {
  const navigate = useLocaleNavigate();

  return (
    <>
      {getRouteConfig(mainRoutes).showGnb !== false && (
        <div
          style={{
            padding: '8px 16px',
            boxSizing: 'border-box',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Row width="100%" justifyContent="space-between" alignItems="center">
            <Row
              position="relative"
              justifyContent="space-between"
              width="100%"
              alignItems="center"
              onClick={() => {
                navigate('/');
              }}
            >
              <img
                src={'/images/logo.png'}
                alt="logo"
                style={{
                  width: 128,
                  imageRendering: '-webkit-optimize-contrast', // 선명도 개선
                }}
              />
            </Row>

            <LanguageSelector />
          </Row>
        </div>
      )}
    </>
  );
};

export default MobileGnb;

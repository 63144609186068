import React, { Suspense, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, useMediaContext } from 'saladsoft-primitive';
import { ListingDto } from 'services/data-contracts';
import { useTranslation } from 'react-i18next';
import { getUrlParam } from 'libs/helper/utils';
import ServiceHelper from 'stores/ServiceHelper';
import i18n from 'i18n';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import { observer } from 'mobx-react-lite';
import { useStoreWrapper } from 'stores/StoreHelper';
import useToken from 'hooks/useToken';
import { motion } from 'framer-motion';
import SearchFilter from './SearchFilter';
import { HanokSearchField } from 'components/input/HanokSearchField';
import { useNavigationType } from 'react-router-dom';

const ListingItem = React.lazy(() => import('./ListingItem'));

interface ListingListProps {
  listings?: ListingDto[];
  hasMore?: boolean;
  showFilter?: boolean;
  itemVariants?: any;
}

const ListingList = observer(
  ({ listings, hasMore, showFilter = true, itemVariants }: ListingListProps) => {
    const navigate = useLocaleNavigate();
    const startDate = getUrlParam('startdate');
    const endDate = getUrlParam('enddate');
    const guests = getUrlParam('guests');

    const { validUser } = useToken();
    const { filterStore } = useStoreWrapper();
    const { isMobile } = useMediaContext();
    const { t } = useTranslation();

    const [favoriteListings, setFavoriteListings] = useState<ListingDto[]>([]);
    const navigationType = useNavigationType();
    const shouldAnimate = navigationType !== 'POP';

    const fetchFavoriteListings = () => {
      ServiceHelper.myAccountService?.getFavoriteListings({ locale: i18n.language }).then((res) => {
        setFavoriteListings(res.data);
      });
    };

    useEffect(() => {
      if (validUser() && listings?.length && listings?.length > 0) {
        if (listings?.length && listings?.length > 0) {
          fetchFavoriteListings();
        }
      }
    }, [listings]);

    return (
      <>
        {!isMobile && (
          <div
            style={{
              paddingBottom: showFilter ? 80 : 0,
              pointerEvents: 'none',
              position: 'relative',
              zIndex: -1,
            }}
          />
        )}

        {showFilter && (
          <>
            {!filterStore.isLoading && (
              <div
                style={{
                  position: 'sticky',
                  top: isMobile ? 95 : 42,
                  // zIndex: 1,
                  zIndex: 100,
                  background: '#fff',
                }}
              >
                <SearchFilterWrapper className="page-container">
                  <SearchFilter total={listings?.length || 0} />
                </SearchFilterWrapper>
                {isMobile && (
                  <Row
                    width={'100%'}
                    background={'#F8F8F8'}
                    height={40}
                    borderBottom={'1px solid #E4E4E4'}
                  >
                    <HanokSearchField onKeywordChange={() => {}} />
                  </Row>
                )}
              </div>
            )}
          </>
        )}

        <ListingContainer>
          <Suspense
            fallback={
              <NoContents>
                <img style={{ width: 100 }} src="/images/butlerlee-loading.gif" alt="loading" />
              </NoContents>
            }
          >
            {listings?.map((house, index) => (
              <ListItemWrapper key={house.id}>
                <motion.div
                  variants={shouldAnimate ? itemVariants : undefined}
                  initial={
                    shouldAnimate ? (itemVariants ? undefined : { opacity: 0, y: 50 }) : false
                  }
                  animate={
                    shouldAnimate ? (itemVariants ? undefined : { opacity: 1, y: 0 }) : false
                  }
                  transition={
                    shouldAnimate
                      ? itemVariants
                        ? undefined
                        : {
                            delay: index * 0.1,
                            duration: 0.5,
                            type: 'spring',
                            stiffness: 100,
                            damping: 15,
                          }
                      : { duration: 0 }
                  }
                  style={{ width: '100%', height: '100%' }}
                >
                  <ListingItem
                    house={house}
                    isFavorite={favoriteListings?.some((listing) => listing.id === house.id)}
                    onClick={() => {
                      navigate(
                        `/listing/${house.id}?startdate=${startDate || ''}&enddate=${endDate || ''}&guests=${guests || ''}`,
                      );
                    }}
                  />
                </motion.div>
              </ListItemWrapper>
            ))}
          </Suspense>

          {listings?.length === 0 && <NoContents>{t('No contents')}</NoContents>}
        </ListingContainer>
      </>
    );
  },
);

const ListingContainer = styled.div`
  display: flex;
  margin-bottom: 100px;
  gap: 32px;
  margin-top: 32px;
  flex-wrap: wrap;

  @media ${(props) => props.theme.media.mobile} {
    gap: 16px;
    margin-top: 0px;
    box-sizing: border-box;
    padding: 16px;
  }

  @media ${(props) => props.theme.media.tablet} {
    flex-wrap: wrap;
    gap: 20px;
  }
`;

const SearchFilterWrapper = styled.div`
  @media ${(props) => props.theme.media.mobile} {
    background: #fff;
    position: relative;
    padding: 0 16px;
    box-sizing: border-box;
  }
`;

const NoContents = styled.div`
  width: 100%;
  height: calc(100vh - 272px);
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  text-align: center;
`;

const ListItemWrapper = styled.div`
  width: calc(33.333% - 21.333px);
  margin-bottom: 24px;

  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
    margin-bottom: 5px;
  }
`;

export default ListingList;

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: 'Pretendard', sans-serif;
      letter-spacing: -0.055px;
      font-style: normal;
    }
    
  :root {
    --primary-color: #4E433C;
    --secondary-color: #92897B;
    --tertiary-color: #DED8D4;
    --quaternary-color: #1F1E1D;
    --disabled-color: #D8D8D8;
    --point-color: #920000;
    --padding: 10px;
    --margin: 20px;
    --gnb-height:85px;
    --border-radius:8px;
    --border-color:#ebebeb;
    --background:#F3F4F1;
    --max-width:1200px;
  }

  // 모바일 BottomSheet 스타일 초기화
  [data-rsbs-header] {
    display: none !important;
  }
   
   [data-rsbs-content] > div {
    margin-top: 0 !important;
   }


  // Toast 컨테이너 스타일
  .Toastify__toast-container {
    padding: 0;
    width: 100%;
    max-width: 500px;
  }

  // Toast 스타일
  .Toastify__toast {
    margin-bottom: 1rem;
    border-radius: 8px;
    padding: 8px 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    font-family: 'Pretendard', sans-serif;
  }

  // 성공 Toast
  .Toastify__toast-theme--colored.Toastify__toast--success {
    background: rgba(0, 0, 0, 0.80) !important;
    color: white;
  }

  // 에러 Toast
  .Toastify__toast-theme--colored.Toastify__toast--error {
    color: white;
    border-radius: 8px;
    background: rgba(230, 44, 67, 0.80) !important;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.10), 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
  }

  // Toast 내용
  .Toastify__toast-body {
    font-size: 14px;
    line-height: 1.5;
    padding: 0;
    font-weight: 500;
  }

  // 커스텀 X 아이콘
  .Toastify__close-button > svg {
    display: none;
  }
   .Toastify__close-button {
    width: 20px;
    height: 20px;
    opacity: 1;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13' fill='none'%3E%3Cpath d='M12 1L1.4295 11.5705' stroke='white' stroke-width='1.3' stroke-linecap='round'/%3E%3Cpath d='M1 1L11.5705 11.5705' stroke='white' stroke-width='1.3' stroke-linecap='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    margin-left: 12px;
    align-self: center;

    &:hover {
      opacity: 0.8;
    }

    &:focus {
      outline: none;
    }
  }

  // 성공 메시지 배경색 수정
   .Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: var( #1F1E1D)! important;
  }

  // Success 아이콘 교체
  .Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter {
    margin-right: 12px;
    width: 20px;
    height: 20px;
  }
  .Toastify__toast-theme--colored.Toastify__toast--success .Toastify__toast-icon > svg {
    display: none;
  }
  .Toastify__toast-theme--colored.Toastify__toast--success .Toastify__toast-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M15.8869 6L8.62897 13.2577L5 9.62886' stroke='white' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
  }

  // Error 아이콘 교체
  .Toastify__toast-theme--colored.Toastify__toast--error .Toastify__toast-icon > svg {
    display: none;
  }
  .Toastify__toast-theme--colored.Toastify__toast--error .Toastify__toast-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z' stroke='white' stroke-width='1.3' stroke-miterlimit='10'/%3E%3Cpath d='M10 6.25V10.625' stroke='white' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10 14.375C10.5178 14.375 10.9375 13.9553 10.9375 13.4375C10.9375 12.9197 10.5178 12.5 10 12.5C9.48223 12.5 9.0625 12.9197 9.0625 13.4375C9.0625 13.9553 9.48223 14.375 10 14.375Z' fill='white'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
  }
  .Toastify__toast-theme--colored.Toastify__toast--error .Toastify__toast-icon {
    margin-right: 12px;
  }
`;

export default GlobalStyle;

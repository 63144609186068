import BTButton from 'components/input/BTButton';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, useMediaContext } from 'saladsoft-primitive';
import { useStoreWrapper } from 'stores/StoreHelper';

const LoginConfirmModal = ({ listingId }: { listingId: number }) => {
  const { t } = useTranslation();
  const { uiStore } = useStoreWrapper();
  const navigate = useLocaleNavigate();
  const { isMobile } = useMediaContext();

  const closeModal = () => {
    if (isMobile) {
      uiStore?.universalModal.close();
    } else {
      uiStore?.modal.close();
    }
  };

  return (
    <div style={{ padding: 30, paddingTop: 0 }}>
      <div style={{ fontSize: 20, fontWeight: 700, marginBottom: 10 }}>{t('loginRequired2')}</div>
      <div style={{ fontSize: 16, fontWeight: 400 }}>{t('loginRequiredForService')}</div>
      <div style={{ fontSize: 16, fontWeight: 400 }}>{t('orSignup')}</div>
      <Row gap={10} marginTop="40px" width="100%" justifyContent="space-between">
        <Row gap={10}>
          <BTButton
            onClick={() => {
              closeModal();
              localStorage.setItem('favoriteListingId', listingId?.toString());
              navigate('/login');
            }}
          >
            {t('login')}
          </BTButton>
          <BTButton
            onClick={() => {
              closeModal();
              localStorage.setItem('favoriteListingId', listingId?.toString());
              navigate('/signup');
            }}
          >
            {t('signup')}
          </BTButton>
        </Row>
        <BTButton
          type="outline"
          onClick={() => {
            closeModal();
          }}
        >
          {t('cancel')}
        </BTButton>
      </Row>
    </div>
  );
};

export default LoginConfirmModal;

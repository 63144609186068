import Section from 'components/layouts/Section';
import i18n from 'i18n';
import React, { useEffect, useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BulkPriceRequest, ListingDto, ListingLowestPriceDto } from 'services/data-contracts';
import ServiceHelper from 'stores/ServiceHelper';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperType } from 'swiper';
import { Scrollbar, Navigation } from 'swiper/modules';
import RecommendListingItem from 'components/commons/RecommendListingItem';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import useToken from 'hooks/useToken';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const RecommendListings = ({
  listingId,
  onClick,
}: {
  listingId?: string;
  onClick?: (index: number) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useLocaleNavigate();
  const { validUser } = useToken();

  const [listings, setListings] = useState<ListingDto[]>([]);
  const [favoriteListings, setFavoriteListings] = useState<ListingDto[]>([]);
  const [lowestPrices, setLowestPrices] = useState<ListingLowestPriceDto[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const uniqueId = useId().replace(/:/g, '');
  const prevBtnId = `swiper-prev-${uniqueId}`;
  const nextBtnId = `swiper-next-${uniqueId}`;

  const fetchNearBy = (listingId: number) => {
    ServiceHelper.listingService
      ?.getRelatedListings(listingId, {
        locale: i18n.language,
      })
      .then((respont) => {
        if (respont) {
          setListings(respont.data);
          const ids = respont.data?.map((listing) => listing.id);
          const bulkPriceRequest: BulkPriceRequest = {
            listingIds: ids as number[],
            startDate: '',
            endDate: '',
            adults: 2,
          };

          ServiceHelper.listingService?.getBulkPrices(bulkPriceRequest).then((res) => {
            setLowestPrices(res.data);
          });
        }
      });
  };

  const fetchFavoriteListings = () => {
    if (validUser()) {
      ServiceHelper.myAccountService?.getFavoriteListings({ locale: i18n.language }).then((res) => {
        setFavoriteListings(res.data);
      });
    }
  };

  const handleClick = (listing?: ListingDto) => {
    navigate(`/listing/${listing?.id}`);
  };

  const handleButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleSlideClick = () => {
    if (onClick) {
      onClick(activeIndex);
    }
  };

  useEffect(() => {
    if (listingId) {
      const listingIdNumber = parseInt(listingId, 10);
      if (listingIdNumber) {
        fetchNearBy(listingIdNumber);
      }
    }
  }, [listingId]);

  useEffect(() => {
    if (listings.length > 0) {
      fetchFavoriteListings();
    }
  }, [listings]);

  return (
    <>
      {listings && listings.length > 0 && (
        <Section title={t('RecommendListings')}>
          <SwiperWrapper>
            <Swiper
              spaceBetween={10}
              slidesPerView="auto"
              scrollbar={{
                hide: true,
              }}
              navigation={{
                prevEl: `#${prevBtnId}`,
                nextEl: `#${nextBtnId}`,
              }}
              modules={[Scrollbar, Navigation]}
              className={`mySwiper-${uniqueId}`}
              loop={true}
              onClick={handleSlideClick}
              onSlideChange={(swiper) => {
                setActiveIndex(swiper.realIndex);
              }}
            >
              {listings.map((listing) => (
                <SwiperSlide key={listing.id} style={{ width: 'auto' }}>
                  <div
                    style={{
                      width: 300,
                      overflow: 'hidden',
                    }}
                  >
                    <RecommendListingItem
                      house={listing}
                      isFavorite={favoriteListings?.some((favorite) => favorite.id === listing.id)}
                      onClick={() => {
                        handleClick(listing);
                      }}
                      lowestPrice={
                        lowestPrices?.find((price) => price.listingId === listing.id)?.price || 0
                      }
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            {/* 고유 ID를 가진 네비게이션 버튼 */}
            <NavButton
              id={prevBtnId}
              className="swiper-button-prev"
              position="left"
              onClick={handleButtonClick}
            >
              <NavIcon>
                <StyledChevronLeftIcon />
              </NavIcon>
            </NavButton>
            <NavButton
              id={nextBtnId}
              className="swiper-button-next"
              position="right"
              onClick={handleButtonClick}
            >
              <NavIcon>
                <StyledChevronRightIcon />
              </NavIcon>
            </NavButton>
          </SwiperWrapper>
        </Section>
      )}
    </>
  );
};

const SwiperWrapper = styled.div`
  position: relative;
  z-index: 0;
  .swiper-scrollbar {
    width: 80%;
    bottom: 20px !important;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover .swiper-scrollbar {
    opacity: 1;
  }
`;

const NavButton = styled.div<{ position: 'left' | 'right' }>`
  position: absolute;
  top: 44%;
  transform: translateY(-50%);
  ${({ position }) => (position === 'left' ? 'left: 20px;' : 'right: 20px;')}
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  opacity: 1 !important;
  visibility: visible;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 0;
  box-sizing: border-box;

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }

  @media ${(props) => props.theme.media.mobile} {
    display: none;
  }
`;

const NavIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const StyledChevronLeftIcon = styled(ChevronLeftIcon)`
  font-size: 24px;
  color: #333;
`;

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  font-size: 24px;
  color: #333;
`;

export default RecommendListings;

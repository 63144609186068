import { makeAutoObservable, toJS } from 'mobx';
import { ListingDto, ListingLowestPriceDto } from 'services/data-contracts';
import { FilterType } from 'types/CommonTypes';

// interface LowestPrice {
//   id: number;
//   price: number;
// }

class FilterStore {
  isLoading: boolean = true;

  listings: ListingDto[];

  filteredListings: ListingDto[] | undefined;

  isFiltered: boolean = false;

  lowestPrices: ListingLowestPriceDto[] = [];

  isReadyLowestPrices: boolean = false;

  loadingRate: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setListings(listings: ListingDto[]) {
    this.listings = listings;
    this.isLoading = false;
  }

  setFilteredListings(filteredListings?: ListingDto[]) {
    this.filteredListings = filteredListings;
  }

  setFiltered(isFiltered: boolean) {
    this.isFiltered = isFiltered;
  }

  
  setLowestPrices(lowestPrices: ListingLowestPriceDto[]) {
    this.lowestPrices = lowestPrices;
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

 

  applyFilters(filterOptions?: FilterType) {
    // 필터 옵션이 없는 경우 모든 리스팅 반환
    if (!filterOptions) {
      this.setFilteredListings(this.listings);
      return this.listings;
    }

    // 필터 옵션에서 지역 ID 배열 가져오기
    const selectedRegionIds = filterOptions.regions || [];
    // 가격 범위 옵션 가져오기
    const priceRange = filterOptions.priceOptions || {};
    // 옵션 ID 배열 가져오기
    const selectedOptions = filterOptions.options || [];
    // 정렬 옵션 가져오기
    const sortOption = filterOptions.sort || { key: 'newest', value: 'Newest' };

    // 필터 조건이 하나도 없고 정렬만 있는 경우
    const hasFilterConditions =
      (selectedRegionIds.length > 0 && !selectedRegionIds.includes(0)) ||
      (priceRange.from !== undefined || priceRange.to !== undefined) ||
      (selectedOptions.length > 0);

    // 필터 조건이 없고 정렬만 있는 경우 모든 리스팅에 정렬 적용
    if (!hasFilterConditions && sortOption) {
      const sortedListings = this.applySorting(this.listings, sortOption);
      this.setFilteredListings(sortedListings);
      return sortedListings;
    }
    // 필터 조건이 있는 경우 필터링 진행
    const filteredListings = this.listings.filter((listing) => {
      // 지역 필터 검사
      let passRegionFilter = true;
      if (selectedRegionIds.length > 0 && !selectedRegionIds.includes(0)) {
        if (selectedRegionIds.includes(100)) {
          passRegionFilter = listing.region?.id === 100 || !listing.region;
        } else {
          passRegionFilter = selectedRegionIds.includes(listing.region?.id ?? 0);
        }
      }

      // 가격 필터 검사
      let passPriceFilter = true;
      if (priceRange.from !== undefined || priceRange.to !== undefined) {
        const itemPrice = this.lowestPrices.find((item) => item.listingId === listing.id)?.price;

        if (itemPrice !== undefined) {
          if (priceRange.from !== undefined && itemPrice < priceRange.from) {
            passPriceFilter = false;
          }
          if (priceRange.to !== undefined && itemPrice > priceRange.to) {
            passPriceFilter = false;
          }
        }
      }

      // 옵션 필터 검사
      let passOptionsFilter = true;
      if (selectedOptions.length > 0) {
        passOptionsFilter = selectedOptions.every((optionId) =>
          listing.amenities?.some((option) => option.id === optionId),
        );
      }

      return passRegionFilter && passPriceFilter && passOptionsFilter;
    });

    // 필터링 후 정렬 적용
    const sortedListings = this.applySorting(filteredListings, sortOption);

    // 정렬된 결과 저장
    this.setFilteredListings(sortedListings);
    this.isFiltered = true;

    return sortedListings;
  }

  // 정렬 함수 구현
  applySorting(listings: ListingDto[], sortOption: { key: string; value: string }) {
    if (!listings || listings.length === 0) {
      return [];
    }

    // 정렬된 목록의 복사본 생성 (원본 변경 방지)
    const listingsToSort = [...listings];

    switch (sortOption.value) {
      case 'PriceLowToHigh': // 가격 오름차순
        return listingsToSort.sort((a, b) => {
          const priceA = this.lowestPrices.find(item => item.listingId === a.id)?.price || 0;
          const priceB = this.lowestPrices.find(item => item.listingId === b.id)?.price || 0;
          return priceA - priceB;
        });

      case 'PriceHighToLow': // 가격 내림차순
        return listingsToSort.sort((a, b) => {
          const priceA = this.lowestPrices.find(item => item.listingId === a.id)?.price || 0;
          const priceB = this.lowestPrices.find(item => item.listingId === b.id)?.price || 0;
          return priceB - priceA;
        });

      case 'Newest': // 최신순
        return listingsToSort.sort((a, b) => {
          const dateA = a.hostawayId ? new Date(a.hostawayId).getTime() : 0;
          const dateB = b.hostawayId ? new Date(b.hostawayId).getTime() : 0;
          return dateB - dateA;
        });

      default: // 기본 정렬 (ID 기준)
        return listingsToSort;
    }
  }

  resetFilters() {
    this.isFiltered = false;
    this.filteredListings = this.listings;
  }

  resetFiltered() {
    this.isFiltered = false;
    this.filteredListings = this.listings;
  }

  resetReadyLowestPrices() {
    this.isReadyLowestPrices = false;
    this.lowestPrices = [];
  }
}

export default FilterStore;

import React, { useEffect, useState } from 'react';
import { Row, useMediaContext } from 'saladsoft-primitive';
import { useTranslation } from 'react-i18next';
import { useStoreWrapper } from 'stores/StoreHelper';
import { FilterType } from 'types/CommonTypes';
import { HanokSearchField } from 'components/input/HanokSearchField';
import { observer } from 'mobx-react';
import FilterBox from './FilterBox';
import BodyText from './BodyText';
import { Popover } from '@mui/material';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { BarLoader } from 'react-spinners';
import { useSearchParams } from 'react-router-dom';

const SearchFilter = observer(({ total }: { total: number }) => {
  const { t } = useTranslation();

  const { uiStore, filterStore } = useStoreWrapper();
  const { isMobile } = useMediaContext();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [sortType, setSortType] = useState<FilterType['sort']>({ key: 'newest', value: 'Newest' });
  const [displaySortText, setDisplaySortText] = useState<string>('Newest');
  const [sortText, setSortText] = useState({
    asc: t('PriceLowToHigh'),
    desc: t('PriceHighToLow'),
    newest: t('Newest'),
  });
  const [filterCount, setFilterCount] = useState(0);
  const [filterOptions, setFilterOptions] = useState<FilterType | undefined>();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClickFilter = () => {
    uiStore?.universalModal.show({
      modalOption: { style: { width: 640, height: '100%', maxHeight: '80%' } },
      children: <FilterBox onApply={handleApplyFilter} filterOptions={filterOptions} />,
      bottomSheetOptions: {
        title: t('Filter'),
      },
    });
  };

  const handleChangeKeyInput = (value: string) => {
    filterStore.setFilteredListings(
      filterStore.listings?.filter((listing) =>
        listing.name?.toUpperCase().includes(value.toUpperCase()),
      ),
    );
  };

  const updateFilters = (newFilters: any) => {
    // 현재 필터 상태 가져오기
    let currentFilters = {};
    const filtersParam = searchParams.get('filters');

    if (filtersParam) {
      try {
        currentFilters = JSON.parse(decodeURIComponent(filtersParam));
      } catch (e) {
        console.error('Failed to parse filters', e);
      }
    }

    // 새 필터와 병합
    const mergedFilters = { ...currentFilters, ...newFilters };

    // 빈 객체가 아닌 경우에만 URL에 추가
    if (Object.keys(mergedFilters).length > 0) {
      const newParams = new URLSearchParams(searchParams);
      newParams.set('filters', encodeURIComponent(JSON.stringify(mergedFilters)));
      setSearchParams(newParams);
    } else {
      // 필터가 없으면 파라미터 제거
      const newParams = new URLSearchParams(searchParams);
      newParams.delete('filters');
      setSearchParams(newParams);
    }
  };

  const handleSortChange = (type: { key: string; value: string }) => {
    setSortType(type);
    updateFilters({ sort: type.key, sortText: type.value });
  };

  const handleApplyFilter = (filterOptions?: FilterType) => {
    if (!filterOptions) {
      filterStore.resetFilters();
      updateFilters({
        regions: undefined,
        priceRange: undefined,
        options: undefined,
        recommendations: undefined,
        sort: undefined,
        sortText: undefined,
      });
      return;
    }

    // filterStore.applyFilters(filterOptions);
    setFilterOptions(filterOptions);

    // 적용된 필터 요소 수 계산
    let count = 0;
    if (filterOptions.recommendations?.recommended || filterOptions.recommendations?.brandNew) {
      count += 1;
    }

    // regions 필터 확인 (배열이 존재하고 길이가 0보다 큰 경우)
    if (filterOptions.regions && filterOptions.regions.length > 0) {
      count += 1;
    }
    // priceOptions 필터 확인 (from 또는 to가 설정된 경우)
    // 최소/최대 가격 범위인 경우에는 필터 카운트에서 제외
    const minPrice =
      filterStore.lowestPrices.length > 0
        ? Math.min(
            ...filterStore.lowestPrices
              .map((item) => item.price)
              .filter((price): price is number => price !== undefined),
          )
        : 0;
    const maxPrice =
      filterStore.lowestPrices.length > 0
        ? Math.max(
            ...filterStore.lowestPrices
              .map((item) => item.price)
              .filter((price): price is number => price !== undefined),
          )
        : 0;

    if (
      (filterOptions.priceOptions?.from && filterOptions.priceOptions.from !== minPrice) ||
      (filterOptions.priceOptions?.to && filterOptions.priceOptions.to !== maxPrice)
    ) {
      count += 1;
    }

    // options 필터 확인 (배열이 존재하고 길이가 0보다 큰 경우)
    if (filterOptions.options && filterOptions.options.length > 0) {
      count += 1;
    }

    setFilterCount(count);

    // 필터 조건을 하나의 객체로 구성
    const filterParams: any = {};

    if (filterOptions.regions && filterOptions.regions.length > 0) {
      filterParams.regions = filterOptions.regions;
    }

    if (filterOptions.priceOptions?.from || filterOptions.priceOptions?.to) {
      filterParams.priceRange = {
        min: filterOptions.priceOptions?.from,
        max: filterOptions.priceOptions?.to,
      };
    }

    if (filterOptions.options && filterOptions.options.length > 0) {
      filterParams.options = filterOptions.options;
    }

    if (filterOptions.recommendations?.recommended || filterOptions.recommendations?.brandNew) {
      filterParams.recommendations = filterOptions.recommendations;
    }

    // URL 업데이트
    updateFilters(filterParams);
  };

  const handleClickSort = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    // t가 변경될 때마다 sortText 업데이트
    setSortText({
      asc: t('PriceLowToHigh'),
      desc: t('PriceHighToLow'),
      newest: t('Newest'),
    });
  }, [t]);

  useEffect(() => {
    if (sortType) {
      // sortType이나 sortText가 변경될 때 displaySortText 업데이트
      const text = sortText[sortType.key as keyof typeof sortText] || t('Newest');
      setDisplaySortText(text);
    }
  }, [sortType, sortText, t]);

  useEffect(() => {
    if (filterStore?.lowestPrices) {
      const filtersParam = searchParams.get('filters');
      if (filtersParam) {
        try {
          const parsedFilters = JSON.parse(decodeURIComponent(filtersParam));

          // 정렬 상태 복원

          // 필터 상태 복원
          const restoredFilters: FilterType = {
            regions: undefined,
            priceOptions: {
              from: undefined,
              to: undefined,
            },
            options: undefined,
            recommendations: {
              recommended: undefined,
              brandNew: undefined,
            },
          };

          if (parsedFilters.sort) {
            restoredFilters.sort = {
              key: parsedFilters.sort,
              value: parsedFilters.sortText || 'Newest',
            };
          }

          if (parsedFilters.regions) {
            restoredFilters.regions = parsedFilters.regions;
          }

          if (parsedFilters.priceRange) {
            restoredFilters.priceOptions = {
              from: parsedFilters.priceRange.min,
              to: parsedFilters.priceRange.max,
            };
          }

          if (parsedFilters.options) {
            restoredFilters.options = parsedFilters.options;
          }

          if (parsedFilters.recommendations) {
            restoredFilters.recommendations = parsedFilters.recommendations;
          }

          // 필터 적용 - 여기서 handleApplyFilter 호출 제거
          if (Object.keys(restoredFilters).length > 0) {
            if (parsedFilters.sort) {
              setSortType(restoredFilters.sort);
            }
            setFilterOptions(restoredFilters);
            filterStore.applyFilters(restoredFilters);
            handleApplyFilter(restoredFilters);

            // 필터 카운트 계산 로직 직접 추가
            let count = 0;
            if (
              restoredFilters.recommendations?.recommended ||
              restoredFilters.recommendations?.brandNew
            ) {
              count += 1;
            }
            if (restoredFilters.regions && restoredFilters.regions.length > 0) {
              count += 1;
            }
            // 가격 필터도 동일하게 처리
            const minPrice =
              filterStore.lowestPrices.length > 0
                ? Math.min(
                    ...filterStore.lowestPrices
                      .map((item) => item.price)
                      .filter((price): price is number => price !== undefined),
                  )
                : 0;
            const maxPrice =
              filterStore.lowestPrices.length > 0
                ? Math.max(
                    ...filterStore.lowestPrices
                      .map((item) => item.price)
                      .filter((price): price is number => price !== undefined),
                  )
                : 0;

            if (
              (restoredFilters.priceOptions?.from &&
                restoredFilters.priceOptions.from !== minPrice) ||
              (restoredFilters.priceOptions?.to && restoredFilters.priceOptions.to !== maxPrice)
            ) {
              count += 1;
            }
            if (restoredFilters.options && restoredFilters.options.length > 0) {
              count += 1;
            }
            setFilterCount(count);
          }
        } catch (e) {
          console.error('Failed to parse filters', e);
        }
      }
    }
  }, [searchParams, filterStore?.lowestPrices]);

  // 필터만 삭제하는 함수 추가
  const removeFilterFromUrl = () => {
    // 현재 URL에서 filters 파라미터만 제거한 새 URL 생성
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.delete('filters');

    // React Router의 setSearchParams 사용
    setSearchParams(currentParams);

    // 상태 초기화는 이미 버튼 클릭 핸들러에서 처리됨
  };

  return (
    <div>
      {/* {!filterStore.isLoading && ( */}
      {!isMobile && (
        <Row gap={10} marginBottom={20}>
          <HanokSearchField onKeywordChange={handleChangeKeyInput} />
        </Row>
      )}
      {/* )} */}
      <Row
        gap={10}
        justifyContent="space-between"
        padding="12px 0"
        borderBottom="1px solid var(--Grayscale-10, #F0F0F0)"
      >
        <BodyText type="F" style={{ color: 'var(--secondary-color)' }}>
          {/* <>{total > 0 && format(t('A total of {0} hanoks'), total)}</> */}
          {total > 0 && (
            <>
              {t('A total of ')}
              <span style={{ fontWeight: 600 }}> {total}</span>
              {t('hanoks')}
            </>
          )}
        </BodyText>
        <Row gap={10} alignItems="center">
          <Row gap={4} onClick={handleClickFilter} cursor={'pointer'}>
            <img src="/images/filter.svg" alt="check-in" />
            <BodyText type="F">
              {t('Filter')}
              {filterCount > 0 && (
                <span
                  style={{
                    fontSize: '15px',
                    color: '#1C51B6',
                    marginLeft: '4px',
                    fontWeight: 600,
                  }}
                >
                  {filterCount}
                </span>
              )}
            </BodyText>
          </Row>
          <>
            {filterCount > 0 && (
              <CloseSearchButton
                onClick={() => {
                  setFilterOptions(undefined);
                  setFilterCount(0);
                  filterStore.resetFilters();
                  // 필터 파라미터만 삭제
                  removeFilterFromUrl();
                }}
              >
                <CloseIcon style={{ color: '#fff', width: 15, height: 15 }} />
              </CloseSearchButton>
            )}
          </>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2"
            height="16"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path d="M1 0V16" stroke="#E4E4E4" />
          </svg>
          <ButtonRow
            isAvailable={filterStore?.isReadyLowestPrices}
            gap={4}
            cursor={'pointer'}
            onClick={handleClickSort}
          >
            <img src={`/images/sort_${sortType?.value}.svg`} alt="check-in" />
            <BodyText type="F">{displaySortText}</BodyText>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M2.4375 3.9375L6.1875 8.0625L9.9375 3.9375"
                stroke="#1D1D1D"
                strokeWidth="1.1"
                strokeLinecap="round"
              />
            </svg>
          </ButtonRow>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            // temp (찜하기, 필터 z-index 문제 해결 위해 임시로 추가)
            sx={{
              zIndex: 10000,
              '& .MuiPopover-paper': {
                zIndex: 10000,
              },
            }}
          >
            <SortBoxStyle style={{ padding: '16px', minWidth: '200px' }}>
              <BodyText type="F" style={{ marginBottom: '8px', fontWeight: 600 }}>
                {t('Sort by')}
              </BodyText>
              <div
                style={{ padding: '8px', cursor: 'pointer' }}
                onClick={() => {
                  // filterStore.setSort('asc', 'price');
                  handleClosePopover();
                  handleSortChange({ key: 'asc', value: 'PriceLowToHigh' });
                }}
                className="sort-option"
              >
                <BodyText type="F">{t('PriceLowToHigh')}</BodyText>
              </div>
              <div
                style={{ padding: '8px', cursor: 'pointer' }}
                onClick={() => {
                  // filterStore.setSort('desc', 'price');
                  handleClosePopover();
                  handleSortChange({ key: 'desc', value: 'PriceHighToLow' });
                }}
                className="sort-option"
              >
                <BodyText type="F">{t('PriceHighToLow')}</BodyText>
              </div>
              <div
                style={{ padding: '8px', cursor: 'pointer' }}
                onClick={() => {
                  // filterStore.setSort('asc', 'newest');
                  handleClosePopover();
                  handleSortChange({ key: 'newest', value: 'Newest' });
                }}
                className="sort-option"
              >
                <BodyText type="F">{t('Newest')}</BodyText>
              </div>
            </SortBoxStyle>
          </Popover>
        </Row>
      </Row>
    </div>
  );
});

const ButtonRow = styled(Row)<{ isAvailable: boolean }>`
  cursor: pointer;
`;

const SortBoxStyle = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15) !important;
  padding: 16px;
  margin: 20px;
  z-index: 1000;

  .sort-option {
    border-radius: 4px;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #f5f5f5;
    }
  }
`;

const CloseSearchButton = styled.div`
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c6c6c6;
  border-radius: 50%;
`;

const FilterLoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  @media ${(props) => props.theme.media.mobile} {
    position: relative !important;
  }
`;
export default SearchFilter;

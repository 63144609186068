import React, { useEffect } from 'react';
import { Row, useMediaContext } from 'saladsoft-primitive';
import { AdminListingImageDto, ListingDto } from 'services/data-contracts';
import FavoriteButton from 'components/favorite/FavoriteButton';
import styled from 'styled-components';

const DetailPhotoBox = ({
  images,
  onClick,
  listing,
}: {
  images?: AdminListingImageDto[];
  onClick: (index: number) => void;
  listing?: ListingDto;
}) => {
  const { isMobile } = useMediaContext();

  // 도면(Plan) 이미지 제외
  const listingImages = images?.filter((image) => !image.isPlan) || [];

  return (
    <Container style={{ cursor: 'pointer' }}>
      <Row gap={4} height="100%">
        <MainImageWrapper onClick={() => onClick(0)}>
          <MainImage src={listingImages[0]?.url} alt="메인 이미지" />
          {isMobile && (
            <MobileIconBox>
              <ShareButton
                onClick={async (e) => {
                  e.stopPropagation();
                  if (navigator.share) {
                    try {
                      await navigator.share({
                        title: listing?.name,
                        text: listing?.title,
                        url: window.location.href,
                      });
                    } catch (error) {
                      console.error('공유 실패:', error);
                    }
                  } else {
                    navigator?.clipboard?.writeText(window.location.href);
                  }
                }}
              >
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/85a31883ce749a20368137aedcdbb1e0a0b119184233c6b907f31a7f44a8c149"
                  alt="share"
                />
              </ShareButton>
              <FavoriteButton
                listingId={listing?.id as number}
                isFavorite={listing?.isFavorite as boolean}
              />
            </MobileIconBox>
          )}
        </MainImageWrapper>
        <>
          {!isMobile && (
            <SubImagesWrapper>
              <SubImageGrid>
                <Image
                  src={listingImages[1]?.thumbnailUrl}
                  alt="no-image"
                  onClick={() => onClick(1)}
                />
                <Image
                  src={listingImages[2]?.thumbnailUrl}
                  alt="no-image"
                  onClick={() => onClick(2)}
                />
                <Image
                  src={listingImages[3]?.thumbnailUrl}
                  alt="no-image"
                  onClick={() => onClick(3)}
                />
                <Image
                  src={listingImages[4]?.thumbnailUrl}
                  alt="no-image"
                  onClick={() => onClick(4)}
                />
              </SubImageGrid>
            </SubImagesWrapper>
          )}
        </>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  height: 477px;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  @media ${(props) => props.theme.media.mobile} {
    height: 258px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
`;

const MainImageWrapper = styled.div`
  flex: 0.6;
  height: 100%;
  @media ${(props) => props.theme.media.mobile} {
    flex: 1;
  }
`;

const MainImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SubImagesWrapper = styled.div`
  flex: 0.4;
  height: 100%;
  aspect-ratio: 1/1.5;
`;

const SubImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 4px;
  height: 100%;
  aspect-ratio: 1;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  height: 100%;
  aspect-ratio: 1;
`;

const MobileIconBox = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  gap: 8px;
  z-index: 1;
  align-items: center;
`;

const ShareButton = styled.button`
  background: transparent;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;

  img {
    width: 20px;
    height: 20px;
  }
`;

export default DetailPhotoBox;

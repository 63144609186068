import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NumberFormat, Row, useMediaContext } from 'saladsoft-primitive';
import { ListingDto, TagDto } from 'services/data-contracts';
import ServiceHelper from 'stores/ServiceHelper';
import i18n from 'i18n';
import { useStoreWrapper } from 'stores/StoreHelper';
import BodyText from './BodyText';
import Subtitle from './Subtitle';
import TagList from './TagList';
import FavoriteButton from '../favorite/FavoriteButton';
import { format } from 'react-string-format';
import { useTranslation } from 'react-i18next';

const RecommendListingItem = ({
  house,
  onClick,
  lowestPrice,
  isFavorite,
}: {
  house: ListingDto;
  onClick: () => void;
  isFavorite?: boolean;
  lowestPrice: number;
}) => {
  const { isMobile } = useMediaContext();
  const { filterStore } = useStoreWrapper();
  const { t } = useTranslation();
  // const [tags, setTags] = useState<TagDto[]>([]);
  const [lowestPriceValue, setLowestPrice] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    setLowestPrice(lowestPrice);
  }, [lowestPrice]);

  return (
    <BoxLayout onClick={onClick}>
      <div style={{ position: 'relative' }}>
        <PhotoLayout image={house?.images?.[0]?.url} />
        <FavoriteWrapper>
          <FavoriteButton listingId={house.id as number} isFavorite={isFavorite || false} />
        </FavoriteWrapper>
      </div>
      <InfoLayout>
        <TagList
          tags={house.tags || []}
          onClick={(e, tag) => {
            e.stopPropagation();
          }}
        />
        <Subtitle style={{ marginTop: '12px' }} type={isMobile ? 'C' : 'A'}>
          {house.name}
        </Subtitle>
        <BodyText type="I" style={{ color: '#8E8E8E', marginBottom: '16px' }}>
          {house.address}
        </BodyText>
        <Row gap={6}>
          <BodyText type={isMobile ? 'I' : 'F'} style={{ color: '#555555' }}>
            {format(
              t('Occupancy'),
              <span style={{ color: '#1C51B6' }}>
                {format(t('StandardGuests'), house.minGuest || 0)}
              </span>,
              <span>{format(t('MaxGuests'), house.maxGuest || 0)}</span>,
            )}
          </BodyText>
          <Row>
            <ParkingInfoWrapper>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="12"
                viewBox="0 0 2 12"
                fill="none"
              >
                <path d="M0.710938 0V12" stroke="#E4E4E4" />
              </svg>
              {house.hasParking ? (
                <img src="/images/parking.svg" alt="no-parking" style={{ marginLeft: '1px' }} />
              ) : (
                <img src="/images/no-parking.svg" alt="parking" style={{ marginLeft: '1px' }} />
              )}
              <BodyText type={isMobile ? 'I' : 'F'} style={{ color: '#555555' }}>
                {house.hasParking ? t('parkingAvailable') : t('parkingUnavailable')}
              </BodyText>
            </ParkingInfoWrapper>
          </Row>
        </Row>

        <Subtitle type={isMobile ? 'B' : 'A'} style={{ color: '#1D1D1D' }}>
          <Row marginTop={isMobile ? '22px' : '27px'} alignContent="flex-end" alignItems="flex-end">
            <NumberFormat
              value={lowestPriceValue}
              surffix=""
              style={{
                display: 'flex',
                lineHeight: '1',
                alignItems: 'flex-end',
                alignContent: 'flex-end',
                fontWeight: '600',
              }}
            />
            <span
              style={{
                fontSize: isMobile ? 13 : 15,
                color: '#1D1D1D',
                fontWeight: '600',
                letterSpacing: '-0.037px',
                lineHeight: '1',
                display: 'flex',
                alignItems: 'flex-end',
                marginBottom: '2px',
              }}
            >
              {t('won')}~
            </span>
            <div style={{ position: 'relative' }}>
              <InfoIconWrapper
                as="button"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowTooltip(!showTooltip);
                }}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <img src="/images/info.svg" alt="info" />
              </InfoIconWrapper>

              {showTooltip && (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '100%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
                    borderRadius: '4px',
                    zIndex: 1000,
                    marginBottom: '10px',
                    minWidth: '200px',
                    backgroundColor: 'white',
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <PopoverContent>
                    <PopoverDescription>{t('RoomManagementFee')}</PopoverDescription>
                  </PopoverContent>
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '-6px',
                      left: '50%',
                      transform: 'translateX(-50%) rotate(45deg)',
                      width: '12px',
                      height: '12px',
                      backgroundColor: 'black',
                      zIndex: -1,
                    }}
                  />
                </div>
              )}
            </div>
          </Row>
        </Subtitle>
      </InfoLayout>
    </BoxLayout>
  );
};

const BoxLayout = styled.div`
  cursor: pointer;
  flex: 0 0 calc(36.55% - 60px);
  border-radius: 8px;
  border-right: 1px solid var(--Grayscale-20, #e4e4e4);
  border-bottom: 1px solid var(--Grayscale-20, #e4e4e4);
  border-left: 1px solid var(--Grayscale-20, #e4e4e4);

  @media ${(props) => props.theme.media.tablet} {
    flex: 0 0 calc(50% - 20px);
  }

  @media ${(props) => props.theme.media.mobile} {
    flex: 0 0 100%;
  }
`;

const PhotoLayout = styled.div<{ image?: string }>`
  position: relative;
  display: flex;

  width: 100%;
  padding-bottom: 66.67%;
  justify-content: flex-end;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  background: url(${(props) => props.image}) lightgray 50% / cover no-repeat;
`;

const InfoLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  padding: 16px 16px 32px 16px;
`;

const FavoriteWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const ParkingInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
`;

const InfoIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin-left: 2px;
  background: none;
  border: none;
`;

const PopoverContent = styled.div`
  display: flex;
  position: relative;
  padding: 6px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #1f1e1d;
  white-space: nowrap;
  color: var(--Grayscale-0, #fff);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.03px;
`;

const PopoverDescription = styled.p`
  font-size: 12px;
  color: #fff;
`;

export default RecommendListingItem;

import BodyText from 'components/commons/BodyText';
import CaptionText from 'components/commons/CaptionText';
import { observer } from 'mobx-react';
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'saladsoft-primitive';
import { ListingDto } from 'services/data-contracts';
import { useStoreWrapper } from 'stores/StoreHelper';
import styled from 'styled-components';
import SearchModal from 'pages/SearchHanokPage';
import useLocaleNavigate from 'hooks/useLocaleNavigate';

export const HanokSearchField = observer(
  ({
    onKeywordChange,
    isScrolled,
  }: {
    onKeywordChange: (keyword: string) => void;
    isScrolled?: boolean;
  }) => {
    const navigate = useLocaleNavigate();
    const inputRef = useRef<HTMLInputElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const { t } = useTranslation();
    const { filterStore, uiStore } = useStoreWrapper();

    const [candidates, setCandidates] = useState<ListingDto[]>([]);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);


    useEffect(() => {
      if (candidates?.length > 0) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }

      return () => {
        document.body.style.overflow = 'auto';
      };
    }, [candidates]);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
          setCandidates([]);
          setSelectedIndex(-1);
          if (inputRef.current) {
            inputRef.current.value = '';
          }
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    // 하이라이트 처리를 위한 함수 추가
    const highlightText = (text: string, searchValue: string) => {
      if (!searchValue) return text;
      const parts = text.split(new RegExp(`(${searchValue})`, 'gi'));
      return parts.map((part, index) =>
        part.toLowerCase() === searchValue.toLowerCase() ? (
          <HighlightedText key={index}>{part}</HighlightedText>
        ) : (
          part
        ),
      );
    };

    return (
      <SearchFieldContainer>
        <SearchFieldWrapper ref={wrapperRef}>
          <Row gap={10}>
            <img src="/images/search.svg" alt="검색" />
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigate('/searchhanok');
              }}
            >
              {isScrolled ? t('Search by hanok name') : t('Are you looking for a specific hanok?')}
            </div>
          </Row>
          {candidates?.length > 0 && (
            <CandidateWrapper>
              <GuideText>{t('Press Enter to see all results')}</GuideText>
              {candidates.map((candidate, index) => (
                <CandidateItem
                  key={candidate.id}
                  isSelected={index === selectedIndex}
                  onClick={() => {
                    onKeywordChange(candidate.name || '');
                    filterStore.setFiltered(true);
                    setCandidates([]);
                    setSelectedIndex(-1);
                    if (inputRef.current) {
                      inputRef.current.value = '';
                    }
                  }}
                >
                  <BodyText type="A">
                    {highlightText(candidate.name || '', inputRef.current?.value || '')}
                  </BodyText>
                  <CaptionText type="C" style={{ color: 'var(--secondary-color)' }}>
                    {candidate.address}
                  </CaptionText>
                </CandidateItem>
              ))}
            </CandidateWrapper>
          )}
        </SearchFieldWrapper>
      </SearchFieldContainer>
    );
  },
);

const SearchFieldContainer = styled.div`
  display: flex;
  justify-content: center;
  /* @media ${(props) => props.theme.media.mobile} {
    margin-bottom: 20px;
  } */
`;

const SearchFieldWrapper = styled.div`
  position: relative;
  color: var(--92897-b, #92897b);
  font-size: 14px;
`;

const CandidateWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 500px;
  width: 100%;
  min-width: 400px;
  max-height: 300px;
  overflow-y: auto;

  @media ${(props) => props.theme.media.mobile} {
    max-width: calc(100% - 20px);
  }
`;

const CandidateItem = styled.div<{ isSelected: boolean }>`
  padding: 4px 8px;
  cursor: pointer;
  background-color: ${(props) => (props.isSelected ? '#f8f8f8' : 'transparent')};
  text-align: left;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const CancelWrapper = styled.div`
  cursor: pointer;
  background: var(--primary-color);
  padding: 4px 20px;
  font-size: 12px;
  border-radius: 4px;
  color: white;
`;

// 하이라이트된 텍스트를 위한 스타일 컴포넌트 추가
const HighlightedText = styled.span`
  background-color: rgba(255, 200, 0, 0.3);
  font-weight: bold;
`;

const GuideText = styled.div`
  text-align: center;
  font-size: 12px;
  color: #888;
  margin-bottom: 4px;
  margin-top: 4px;
`;

import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SocialSignUpDto, UserProfileDto } from 'services/data-contracts';
import ServiceHelper from 'stores/ServiceHelper';
import { useEffect, useState } from 'react';
import { useModelStateContext } from 'saladsoft-primitive';
import useLocaleNavigate from './useLocaleNavigate';
import { useTranslation } from 'react-i18next';

const useSocialSignUp = () => {
  const navigate = useLocaleNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { setState } = useModelStateContext<SocialSignUpDto>();

  const [userProfile, setUserProfile] = useState<UserProfileDto>();

  // 소셜 로그인 데이터 처리 (localStorage에 저장된 데이터를 가져와서 폼에 채워줌)
  const loadSocialData = () => {
    const socialType = searchParams.get('socialType');
    const isReady = searchParams.get('ready'); // 소셜 로그인 처리 완료 여부

    if (socialType && isReady) {
      const storedSocialData = localStorage.getItem('socialData');

      if (storedSocialData) {
        const socialData = JSON.parse(storedSocialData);
        setState('socialId', socialData.socialId);
        setState('username', socialData.email);
        setState('profile', socialData.profile);
        setState('referrerEmail', socialData.referrerEmail);
        setState('socialAccessToken', socialData.socialAccessToken);
      } else {
        console.warn(t('signup.socialDataNotFound'));
        return;
      }
    }
  };

  useEffect(() => {
    loadSocialData();
  }, [searchParams]);

  // 카카오: 실제 회원가입 처리
  const signUpWithKakao = async (socialInfo: SocialSignUpDto): Promise<boolean> => {
    const data: SocialSignUpDto = {
      ...socialInfo,
      ...userProfile,
      profile: socialInfo.profile,
    };

    try {
      const response = await ServiceHelper.authService?.signUpWithKakao(data);
      if (response?.status === 200) {
        toast.success(t('signup.success'));
        setTimeout(() => {
          navigate('/signup-complete');
        }, 1000);
        return true;
      } else {
        const error = response as any;
        if (error?.error?.data?.errorCode === 'REFERRAL_EMAIL.NOT_FOUND') {
          toast.error(t('signup.referralEmailNotFound'));
        } else {
          toast.error(error?.error?.data?.message || t('signup.signupFailed'));
        }
        return false;
      }
    } catch (error) {
      console.error(t('signup.kakaoSignupFailed'), error);
      toast.error(t('signup.signupFailed'));
      return false;
    }
  };

  // 구글: 실제 회원가입 처리
  const signUpWithGoogle = async (socialInfo: SocialSignUpDto): Promise<boolean> => {
    const data: SocialSignUpDto = {
      ...socialInfo,
      ...userProfile,
      profile: socialInfo.profile,
    };

    try {
      // alert();
      const response = await ServiceHelper.authService?.signUpWithGoogle(data);
      if (response?.status === 200) {
        toast.success(t('signup.success'));
        setTimeout(() => {
          navigate('/signup-complete');
        }, 1000);
        return true;
      } else {
        const error = response as any;
        if (error?.error?.data?.errorCode === 'REFERRAL_EMAIL.NOT_FOUND') {
          toast.error(t('signup.referralEmailNotFound'));
        } else if (error?.error?.data?.errorCode === 'USER_PROFILE.INVALID_PHONE') {
          toast.error(t('signup.invalidPhoneNumber'));
        } else if (error?.error?.data?.errorCode === 'USER_PROFILE.BIRTHDAY_REQUIRED') {
          toast.error(t('signup.birthdayRequired'));
        } else {
          toast.error(error?.error?.data?.message || t('signup.signupFailed'));
        }
        return false;
      }
    } catch (error) {
      console.error(t('signup.googleSignupFailed'), error);
      toast.error(t('signup.signupFailed'));
      return false;
    }
  };

  // 회원가입 API 호출
  const handleSignup = async (socialInfo: SocialSignUpDto): Promise<boolean> => {
    const socialType = searchParams.get('socialType');
    if (socialType === 'kakao') {
      return await signUpWithKakao(socialInfo);
    } else if (socialType === 'google') {
      return await signUpWithGoogle(socialInfo);
    }
    toast.error(t('signup.unsupportedSocialType'));
    return false;
  };

  return { handleSignup, loadSocialData };
};

export default useSocialSignUp;

import React from 'react';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton, Popover } from '@mui/material';
import { CSSProperties, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Row, useModelStateContext } from 'saladsoft-primitive';
import Subtitle from './Subtitle';
import BodyText from './BodyText';
import { useMediaContext } from 'saladsoft-primitive';
import { PropTypes } from 'mobx-react';

export const CountInner = ({
  count,
  maxCount,
  useZero,
  style,
  className,
  useConfirm = true,
  onChange,
  onClose,
}: {
  count?: number;
  maxCount?: number;
  useZero?: boolean;
  style?: CSSProperties;
  className?: string;
  useConfirm?: boolean;
  onChange: (guest: number) => void;
  onClose?: () => void;
}) => {
  const { t } = useTranslation();
  const { broadcast } = useModelStateContext();
  const { isMobile } = useMediaContext();

  const [guestCount, setGuestCount] = useState<number>(count || 2);
  const [maxCount2, setMaxCount] = useState<number>(maxCount || 12);

  const handleDecrease = () => {
    setGuestCount((prev) => {
      if (prev > (useZero ? 0 : 1)) {
        onChange?.(prev - 1);
        return prev - 1;
      } else {
        return prev;
      }
    });
  };

  const handleIncrease = () => {
    setGuestCount((prev) => {
      if (prev < maxCount2) {
        onChange?.(prev + 1);
        broadcast({ id: 'ACTION' });
        return prev + 1;
      } else {
        toast.success(t('The maximum quantity has been exceeded.'));
        return prev;
      }
    });
  };

  useEffect(() => {
    if (count) setGuestCount(count);
  }, [count]);

  useEffect(() => {
    if (maxCount) setMaxCount(maxCount);
  }, [maxCount]);

  return (
    <>
      <DateInnerContainer style={style} className={className}>
        <Row
          gap={isMobile ? 20 : 60}
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <div className="flex-1" style={{ width: '50%' }}>
            <Subtitle type="C" style={{ marginBottom: 8 }}>
              {t('Guests')}
            </Subtitle>
            <BodyText
              type="I"
              style={{
                wordBreak: 'keep-all',
                whiteSpace: 'pre-wrap',
                color: '#808080',
              }}
            >
              {t('guestNotice')}
            </BodyText>
          </div>

          <Row
            className="flex-2"
            gap={isMobile ? 16 : 20}
            // justifyContent="space-between"
            width={isMobile ? 'auto' : '50%'}
          >
            <IconButton
              onClick={handleDecrease}
              style={{
                border: '1px solid var(--Grayscale-20, #E4E4E4)',
                width: '32px',
                height: '32px',
                background: guestCount === 1 ? 'var(--Grayscale-20, #E4E4E4)' : 'transparent',
              }}
            >
              <RemoveIcon />
            </IconButton>
            <Subtitle type="A" style={{ fontSize: 24 }}>
              <Row>
                <span>{guestCount}</span>
                <span>{t('people')}</span>
              </Row>
            </Subtitle>
            <IconButton
              onClick={handleIncrease}
              style={{
                border: '1px solid var(--Grayscale-20, #E4E4E4)',
                width: '32px',
                height: '32px',
              }}
            >
              <AddIcon />
            </IconButton>
          </Row>
        </Row>
        {!isMobile && <Divider className="count-inner-divider" />}
        {useConfirm && (
          <ConfirmButtonWrapper>
            <ConfirmButton
              onClick={() => {
                onChange?.(guestCount);
                onClose?.();
              }}
            >
              {t('Confirm')}
            </ConfirmButton>
          </ConfirmButtonWrapper>
        )}
      </DateInnerContainer>
    </>
  );
};

const DateInnerContainer = styled.div`
  padding: 20px;
  background: #fff;
  box-shadow: 0px 0px 22.5px 0px rgba(144, 155, 169, 0.25) !important;
  border-radius: 20px;
  margin: 20px;

  @media ${(props) => props.theme.media.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: var(--gray-gray-10, #f0f0f0);
  margin-top: 40px;
  width: calc(100% + 48px);
  margin-left: -24px;
`;

const ConfirmButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media ${(props) => props.theme.media.mobile} {
    margin-bottom: 20px;
  }
`;

const ConfirmButton = styled.button`
  padding: 12px 24px;
  margin-top: 12px;
  height: 48px;
  border-radius: 8px;
  background: var(--4-e-433-c, #4e433c);
  border: 0px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.037px;
  color: var(--Grayscale-0, #fff);
  cursor: pointer;
  width: 50%;

  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;

import FavoriteButton from 'components/favorite/FavoriteButton';
import NaverMap from 'components/maps/NaverMap';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberFormat, Row, useMediaContext } from 'saladsoft-primitive';
import { ListingDto, ListingPriceDto, TagDto } from 'services/data-contracts';
import ServiceHelper from 'stores/ServiceHelper';
import { useStoreWrapper } from 'stores/StoreHelper';
import styled from 'styled-components';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { toast } from 'react-toastify';
import Maps from 'components/commons/Maps';

const ListingSummary = ({
  listing,
  priceInfo,
}: {
  listing?: ListingDto;
  priceInfo?: ListingPriceDto;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaContext();
  const { uiStore } = useStoreWrapper();

  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [tags, setTags] = useState<TagDto[]>([]);

  const handleMapClick = () => {
    const pcStyle = {
      width: '100%',
      maxWidth: '1200px',
      height: '100%',
      maxHeight: '80vh',
    };

    const mobileStyle = {
      width: '100%',
      height: '100%',
    };

    uiStore?.modal.show({
      title: listing?.name,
      style: isMobile ? mobileStyle : pcStyle,
      children: (
        <MapWrapper>
          <NaverMap
            style={{ height: isMobile ? '50%' : '100%' }}
            mapId="listingMap"
            mapStyle={{ height: '100%' }}
            geolocations={[
              {
                latitude: listing?.latitude as number,
                longitude: listing?.longitude as number,
              },
            ]}
            title={listing?.name as string}
          />
          {isMobile && (
            <>
              <MobileInfo>
                <Row gap={10} flexWrap="wrap">
                  <Row gap={5}>
                    <LocationOnOutlinedIcon />
                    <div>{listing?.address}</div>
                  </Row>
                  <Row
                    alignItems="center"
                    gap={5}
                    border="1px solid #D8D8D8"
                    padding="4px 8px"
                    borderRadius="4px"
                    onClick={() => {
                      navigator.clipboard.writeText(listing?.address || '');
                      toast.success(t('copiedToClipboard'));
                    }}
                  >
                    <ContentCopyOutlinedIcon style={{ fontSize: '16px' }} />
                    <div style={{ fontSize: '13px', fontWeight: '500' }}>{t('copyClipboard')}</div>
                  </Row>
                </Row>
              </MobileInfo>
              <div style={{ marginTop: 50 }}>
                <Maps listing={listing} />
              </div>
            </>
          )}
        </MapWrapper>
      ),
    });
  };

  const fetchTags = () => {
    ServiceHelper.listingService
      ?.getTagsByListing(listing?.id as number, {
        locale: i18n.language,
      })
      .then((res) => {
        setTags(res.data);
      });
  };

  useEffect(() => {
    if (priceInfo) {
      setTotalPrice(Number(priceInfo.price));
    }
  }, [priceInfo]);

  useEffect(() => {
    if (listing) {
      fetchTags();
    }
  }, [listing]);

  return (
    <ListingDetail>
      <ListingDetailContainer>
        <ListingDetailTop>
          <TagLine>
            <Tags>
              {tags.map((tag) => (
                <Labeltag key={tag.id}>#{tag.name}</Labeltag>
              ))}
            </Tags>
            {!isMobile && (
              <IconBox>
                <div
                  onClick={async () => {
                    if (navigator.share) {
                      try {
                        await navigator.share({
                          title: listing?.name,
                          text: listing?.title,
                          url: window.location.href,
                        });
                      } catch (error) {
                        console.error('공유 실패:', error);
                      }
                    } else {
                      // share API를 지원하지 않는 브라우저를 위한 폴백
                      navigator.clipboard.writeText(window.location.href);
                    }
                  }}
                >
                  <Img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/85a31883ce749a20368137aedcdbb1e0a0b119184233c6b907f31a7f44a8c149?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
                  />
                </div>

                <FavoriteButton
                  listingId={listing?.id as number}
                  isFavorite={listing?.isFavorite as boolean}
                />
              </IconBox>
            )}
          </TagLine>
          <TitleLine>
            <Div3>{listing?.name}</Div3>
            <Div4>{listing?.title}</Div4>
          </TitleLine>
        </ListingDetailTop>
        <ListingDetailBottom>
          <Div5>
            <Div6 style={{}}>
              <Img3
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6c0a52f038874fd9cdf79c5902fec48dd1b35498be03877f5eacfec75c33e2c?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
              />
              <Div7>{listing?.address}</Div7>
            </Div6>
            <Div8 onClick={() => {}}>
              <Img4
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/257e69ee7b3ecc602c6e8335782a2e1cb3ece74973276e490fea0150a7f3aadd?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
              />
              <Div9
                onClick={() => {
                  handleMapClick();
                }}
              >
                {t('map')}
              </Div9>
            </Div8>
          </Div5>
          {!isMobile && (
            <Div10>
              <Div11>
                {priceInfo && (
                  <Row>
                    <NumberFormat value={totalPrice} prefix="₩" />
                    <>{!priceInfo?.nights && <div>~</div>}</>
                  </Row>
                )}
              </Div11>
            </Div10>
          )}
        </ListingDetailBottom>
      </ListingDetailContainer>
    </ListingDetail>
  );
};

const ListingDetail = styled.div`
  justify-content: end;
  align-self: stretch;
  border-radius: 0px 0px 12px 12px;
  background: var(--1-f-1-e-1-d, #1f1e1d);
  background-color: var(--1-f-1-e-1-d, #1f1e1d);
  display: flex;
  flex-direction: column;
  padding: 20px 24px 32px;
  @media (max-width: 991px) {
    padding: 0 20px;
    border-radius: 0px;
  }
`;

const ListingDetailContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ListingDetailTop = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const TagLine = styled.div`
  display: flex;
  width: 100%;
  align-items: start;
  gap: 40px 100px;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Tags = styled.div`
  display: flex;
  align-items: start;
  gap: 4px;
  color: var(--92897-b, #92897b);
  white-space: nowrap;
  letter-spacing: -0.03px;
  justify-content: start;
  font:
    500 12px Pretendard,
    sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    margin-top: 24px;
  }
`;

const Labeltag = styled.div`
  align-self: stretch;
  border-radius: 2px;
  background-color: rgba(45, 45, 45, 1);
  gap: 6px;
  padding: 6px;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div = styled.div`
  align-self: stretch;
  border-radius: 2px;
  background-color: rgba(45, 45, 45, 1);
  gap: 6px;
  overflow: hidden;
  padding: 6px;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const IconBox = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  gap: 20px;
  justify-content: start;
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: stretch;
  margin: auto 0;
  cursor: pointer;
`;

const TitleLine = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  font-family: Pretendard, sans-serif;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div3 = styled.div`
  color: rgba(255, 255, 255, 1);
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -0.08px;
  color: var(--gray-gray-0, #fff);
  font-family: Pretendard;
  font-style: normal;
  line-height: 150%; /* 48px */

  @media ${(props) => props.theme.media.mobile} {
    font-size: 22px;
    letter-spacing: -0.055px;
  }
`;

const Div4 = styled.div`
  color: var(--Grayscale-50, #8e8e8e);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.04px;
  margin-top: 5px;

  @media ${(props) => props.theme.media.mobile} {
    font-size: 14px;
    letter-spacing: -0.035px;
  }
`;

const ListingDetailBottom = styled.div`
  display: flex;
  margin-top: 32px;
  width: 100%;
  align-items: center;
  gap: 4px;
  justify-content: start;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-bottom: 32px;
  }
`;

const Img3 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  align-self: stretch;
  margin: auto 0;
`;

const Div5 = styled.div`
  align-self: stretch;
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 12px;
  justify-content: start;
  flex-wrap: wrap;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
  font:
    13px Pretendard,
    sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div6 = styled.div`
  display: flex;
  gap: 4px;
`;

const Div7 = styled.div`
  color: #f0f0f0;
  font-weight: 400;
  align-self: stretch;
  margin: auto 0;
`;

const Div8 = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--92897-b, #92897b);
  font-weight: 500;
  white-space: nowrap;
  letter-spacing: -0.03px;
  justify-content: start;
  margin: auto 0;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img4 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 14px;
  align-self: stretch;
  margin: auto 0;
`;

const Div9 = styled.div`
  cursor: pointer;
  align-self: stretch;
  gap: 4px;
  margin: auto 0;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div10 = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #f8f8f8;
  white-space: nowrap;
  letter-spacing: -0.07px;
  justify-content: start;
  margin: auto 0;
  font:
    600 28px Pretendard,
    sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div11 = styled.div`
  align-self: stretch;
  gap: 4px;
  margin: auto 0;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  @media ${(props) => props.theme.media.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

const MobileInfo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  margin-top: 16px;
  padding: 10px;
`;
export default ListingSummary;

import React, { useId, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import { Scrollbar, Navigation } from 'swiper/modules';
import { ListingImageDto } from 'services/data-contracts';
import { useMediaContext } from 'saladsoft-primitive';
// MUI 아이콘 임포트
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import type { Swiper as SwiperType } from 'swiper';

const ListingImageSwiper = ({
  images,
  useSlider = true,
  onClick,
  useThumbnail,
}: {
  images?: ListingImageDto[];
  useSlider?: boolean;
  onClick?: (index: number) => void;
  useThumbnail?: boolean;
}) => {
  const { isMobile } = useMediaContext();
  // 고유 ID 생성 (React 18 이상)
  const uniqueId = useId().replace(/:/g, '');
  const prevBtnId = `swiper-prev-${uniqueId}`;
  const nextBtnId = `swiper-next-${uniqueId}`;

  // 현재 활성 슬라이드 인덱스 상태 추가
  const [activeIndex, setActiveIndex] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState<SwiperType | null>(null);

  const filteredImages = images?.filter((image) => !image.isPlan) || [];

  // 이벤트 전파 중지 함수
  const handleButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    // e.preventDefault();
  };

  // 슬라이드 클릭 핸들러
  const handleSlideClick = () => {
    if (onClick) {
      onClick(activeIndex); // 현재 활성 슬라이드 인덱스 전달
    }
  };

  return (
    <>
      <GlobalStyle isMobile={isMobile} />
      <SwiperWrapper>
        <Swiper
          scrollbar={{
            hide: !useSlider,
          }}
          navigation={{
            // 고유 ID를 가진 버튼 지정
            prevEl: `#${prevBtnId}`,
            nextEl: `#${nextBtnId}`,
          }}
          loop={true}
          spaceBetween={10}
          modules={[Scrollbar, Navigation]}
          className={`mySwiper-${uniqueId}`} // 고유 클래스명 부여
          onClick={handleSlideClick}
          onSwiper={setSwiperInstance}
          onSlideChange={(swiper) => {
            setActiveIndex(swiper.realIndex);
          }}
        >
          {images
            ?.filter((image) => !image.isPlan)
            .map((image, index) => (
              <SwiperSlide key={index}>
                <ImageContainer>
                  <StyledImage key={index} src={useThumbnail ? image.thumbnailUrl : image.url} />
                </ImageContainer>
              </SwiperSlide>
            ))}
        </Swiper>

        {/* 고유 ID를 가진 네비게이션 버튼 */}
        <NavButton
          id={prevBtnId}
          className="swiper-button-prev"
          position="left"
          onClick={handleButtonClick}
          onMouseDown={handleButtonClick}
        >
          <NavIcon>
            <StyledChevronLeftIcon />
          </NavIcon>
        </NavButton>
        <NavButton
          id={nextBtnId}
          className="swiper-button-next"
          position="right"
          onClick={handleButtonClick}
          onMouseDown={handleButtonClick}
        >
          <NavIcon>
            <StyledChevronRightIcon />
          </NavIcon>
        </NavButton>
      </SwiperWrapper>
    </>
  );
};

const GlobalStyle = createGlobalStyle<{ isMobile: boolean }>`
.swiper-scrollbar {
  opacity: 0;
  transition: opacity 0.3s ease;
  bottom: ${({ isMobile }) => (isMobile ? '20px' : '40px')} !important;
  background:rgba(255, 255, 255, 0.3) !important;
  height: 2px !important;
  width: 70% !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}
.swiper-scrollbar-drag{
  background: #fff;
}
.swiper:hover .swiper-scrollbar,
.swiper-container:hover .swiper-scrollbar {
  opacity: 1 !important;
}
.swiper-scrollbar-draggable {
  opacity: 1;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

/* 버튼 초기 상태에서 완전히 숨김 */
.swiper-button-prev,
.swiper-button-next,
#swiper-prev-*,
#swiper-next-* {
  opacity: 0 !important;
  visibility: hidden !important;
}

/* 호버 시에만 버튼 표시 */
.swiper:hover .swiper-button-prev,
.swiper:hover .swiper-button-next,
.swiper:hover [id^="swiper-prev-"],
.swiper:hover [id^="swiper-next-"],
.swiper-container:hover .swiper-button-prev,
.swiper-container:hover .swiper-button-next,
.swiper-container:hover [id^="swiper-prev-"],
.swiper-container:hover [id^="swiper-next-"] {
  opacity: 1 !important;
  visibility: visible !important;
}
`;

const ImageContainer = styled.div`
  aspect-ratio: 1010/665;
  overflow: hidden;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
`;

const SwiperWrapper = styled.div`
  position: relative;
  z-index: 0;
  .swiper-scrollbar {
    width: 80%;
    bottom: 20px !important;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover .swiper-scrollbar {
    opacity: 1;
  }
`;

// MUI 아이콘 스타일링
const StyledChevronLeftIcon = styled(ChevronLeftIcon)`
  font-size: 24px;
  color: #333;
`;

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  font-size: 24px;
  color: #333;
`;

// 아이콘을 감싸는 컨테이너
const NavIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

// 네비게이션 버튼 스타일
const NavButton = styled.div<{ position: 'left' | 'right' }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ position }) => (position === 'left' ? 'left: 20px;' : 'right: 20px;')}
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  opacity: 0 !important;
  visibility: hidden;
  transition:
    opacity 0.3s ease,
    background-color 0.3s ease,
    visibility 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 0;
  box-sizing: border-box;

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }

  ${SwiperWrapper}:hover & {
    opacity: 1 !important;
    visibility: visible;
  }

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
    ${({ position }) => (position === 'left' ? 'left: 10px;' : 'right: 10px;')}
  }
`;

export default ListingImageSwiper;

import React from 'react';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BookInfo, Dates } from 'types/CommonTypes';
import { CountButton } from '../CountButton';
import { PCDateRangePicker } from './PCDateRangePicker';
import styled from 'styled-components';
import BTButton from 'components/input/BTButton';
import { getUrlParam } from 'libs/helper/utils';
import { useMediaContext } from 'saladsoft-primitive';

/**
 * @description pc용 메인화면의 검색 컴포넌트
 * @param param0
 * @returns
 */
export const PCSearchBox = ({
  isModify = false,
  onChange,
  onSearch,
  dateText,
  useCalendarIcon = true,
}: {
  isModify?: boolean;
  dateText: ReactElement;
  useCalendarIcon?: boolean;
  onChange: (bookInfo: BookInfo) => void;
  onSearch: (bookInfo: BookInfo) => void;
}) => {
  const [guests, setGuests] = useState<number>(2);
  const datesRef = useRef<Dates>({});

  const { t } = useTranslation();
  const { isMobile } = useMediaContext();

  useEffect(() => {
    const startDate = getUrlParam('startdate');
    const endDate = getUrlParam('enddate');
    if (startDate && endDate) {
      datesRef.current = {
        startDate: startDate as string,
        endDate: endDate as string,
      };
    }
  }, [location.search]);

  const isValidDateRange = (startDate: string, endDate: string) => {
    if (!startDate || !endDate) return false;
    const start = new Date(startDate);
    const end = new Date(endDate);

    // 시작일이 종료일보다 앞이어야 하고, 1박 이상이어야 함
    return start < end && end.getTime() - start.getTime() >= 24 * 60 * 60 * 1000;
  };

  return (
    <>
      <Layout>
        <DateLayout>
          <PCDateRangePicker
            onChange={(selectedDate: Dates, guests: number) => {
              if (guests) setGuests(guests);

              if (datesRef.current && selectedDate.startDate && selectedDate.endDate) {
                datesRef.current = selectedDate;
                onChange?.({
                  startDate: selectedDate.startDate,
                  endDate: selectedDate.endDate,
                  guests: guests,
                });
              }
            }}
            useCalendarIcon={useCalendarIcon}
            dateText={dateText}
          />
        </DateLayout>
        <CountButton
          count={guests}
          maxCount={12}
          onChange={(guests: number) => {
            setGuests(guests);
            onChange?.({
              startDate: datesRef.current.startDate || '',
              endDate: datesRef.current.endDate || '',
              guests: guests,
            });
          }}
        />

        <div>
          <BTButton
            onClick={() => {
              const startDate = datesRef.current.startDate || '';
              const endDate = datesRef.current.endDate || '';
              onSearch?.({
                startDate: isValidDateRange(startDate, endDate) ? startDate : '',
                endDate: isValidDateRange(startDate, endDate) ? endDate : '',
                guests: guests,
              });
            }}
            style={{ height: 80, padding: '0 56px' }}
          >
            {t('Search')}
          </BTButton>
        </div>
      </Layout>
    </>
  );
};

const Layout = styled.div`
  margin: auto;
  gap: 10px;
  width: 100%;
  max-width: 1007px;
  height: 100%;
  height: 112px;
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
`;

const DateLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 80px;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--Grayscale-5, #f8f8f8);
`;

import React from 'react';

export const CopyAddressIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M13.7202 5.83508H5.86206V13.6932H13.7202V5.83508Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <path
      d="M4.4878 10.1685H2.73413V2.30688H10.5923V4.49634"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
  </svg>
);

export const BackArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path d="M16 8L7.42 15.8L16 23.6" stroke="#1D1D1D" strokeWidth="1.6" strokeLinecap="round" />
  </svg>
);

export const DividerIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="2" height="12" viewBox="0 0 2 12" fill="none">
    <path d="M1 0V12" stroke="#E4E4E4" />
  </svg>
);

export const CloseIcon = () => (
  <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 4L4 12" stroke="#1d1d1d" strokeWidth="1.2" strokeLinecap="round" />
    <path d="M4 4L12 12" stroke="#1d1d1d" strokeWidth="1.2" strokeLinecap="round" />
  </svg>
);

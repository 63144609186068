import React from 'react';
import PageContainer from 'containers/pc/PageContainer';

import SocialLoginButtons from 'components/login/SocialLoginButtons';

const LoginPage = () => {
  return (
    <PageContainer>
      <SocialLoginButtons type="login" />
    </PageContainer>
  );
};

export default LoginPage;

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Row } from 'saladsoft-primitive';
import styled from 'styled-components';
import { ListingDto } from 'services/data-contracts';
import BodyText from '../components/commons/BodyText';
import CaptionText from '../components/commons/CaptionText';
import ListingList from '../components/commons/ListingList';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import MapList from '../components/commons/MapList';
import CloseIcon from '@mui/icons-material/Close';
import PageContainer from 'containers/pc/PageContainer';
import { format } from 'react-string-format';
import ServiceHelper from 'stores/ServiceHelper';
import i18n from 'i18n';

const SearchHanokPage = observer(() => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const [listings, setListings] = useState<ListingDto[]>([]);
  const [candidates, setCandidates] = useState<ListingDto[]>([]);
  const [searchResult, setSearchResult] = useState<ListingDto[]>();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [isListView, setIsListView] = useState<boolean>(true);
  const [showCandidates, setShowCandidates] = useState<boolean>(false);

  // 하이라이트 처리를 위한 함수 추가
  const highlightText = (text: string, searchValue: string) => {
    if (!searchValue) return text;
    const parts = text.split(new RegExp(`(${searchValue})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === searchValue.toLowerCase() ? (
        <HighlightedText key={index}>{part}</HighlightedText>
      ) : (
        part
      ),
    );
  };

  const fetchListings = () => {
    ServiceHelper?.listingService
      ?.getListings({
        locale: i18n.language,
      })
      .then((res) => {
        setListings(res.data);
      });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // if (candidates.length === 0) return;
    setShowCandidates(true);
    const inputValue = inputRef.current?.value || '';
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        if (selectedIndex === -1) {
          setSelectedIndex(0);
        } else {
          setSelectedIndex((prev) => (prev < candidates?.length - 1 ? prev + 1 : prev));
        }
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev));
        break;
      case 'Enter':
        e.preventDefault();
        if (inputValue?.length < 1) return;
        if (selectedIndex >= 0) {
        }
        if (selectedIndex >= 0 && selectedIndex < candidates?.length) {
          const result = listings?.filter((listing) => listing.id === candidates[selectedIndex].id);
          setSearchResult(result || []);
          if (inputRef.current) {
            inputRef.current.value = candidates[selectedIndex].name || '';
          }
        } else {
          const result = listings?.filter((listing) =>
            listing.name?.toUpperCase().includes(inputValue.toUpperCase()),
          );

          setSearchResult(result || []);
        }
        setCandidates([]);
        setSelectedIndex(-1);
        setShowCandidates(false);
        // if (inputRef.current) {
        //   inputRef.current.value = '';
        // }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchListings();
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, []);

  useLayoutEffect(() => {
    const footer = document.querySelector('.footer') as HTMLElement;
    if (footer) {
      footer.style.display = 'none';
    }
  }, []);

  return (
    <PageContainer>
      <div style={{ position: 'relative', minHeight: 'calc(100vh - 130px)', padding: '0 20px' }}>
        <div className={isListView ? 'page-container' : ''}>
          <Row
            justifyContent="space-between"
            paddingBottom="20px"
            className="page-container"
            page-container
          >
            {isListView ? (
              <div style={{ fontSize: 24, fontWeight: 600, color: '#1D1D1D' }}>
                {t('Search hanok')}
              </div>
            ) : (
              <div
                style={{
                  margin: 'auto',
                  fontSize: 24,
                  fontWeight: 600,
                  color: '#1D1D1D',
                  textAlign: 'center',
                }}
              >
                <img
                  src="/images/channelTalk_logo.webp"
                  alt="channelTalk"
                  style={{ width: 40, height: 40 }}
                />
                <div style={{ fontSize: 17, fontWeight: 600, color: '#1D1D1D' }}>
                  {t('Show all branches')}
                </div>
                <div style={{ fontSize: 13, fontWeight: 400, color: '#1D1D1D' }}>
                  {format(t('butlerleeOperatios'), listings?.length)}
                </div>
              </div>
            )}
            <div style={{ fontSize: 14, fontWeight: 400, color: '#1D1D1D' }}>
              <Row
                cursor="pointer"
                background="rgba(28, 81, 182, 0.05)"
                borderRadius="4px"
                padding="4px 8px"
                gap={4}
                onClick={() => setIsListView(!isListView)}
              >
                {isListView ? (
                  <MapOutlinedIcon style={{ color: '#1C51B6', width: 20, height: 20 }} />
                ) : (
                  <ViewListOutlinedIcon style={{ color: '#1C51B6', width: 20, height: 20 }} />
                )}
                <BodyText type="H" style={{ color: '#1C51B6' }}>
                  {isListView ? t('All locations map') : t('List view')}
                </BodyText>
              </Row>
            </div>
          </Row>
          {isListView && (
            <>
              <Row gap={10} background="#F8F8F8" padding="12px 16px">
                <img src="/images/search.svg" alt="검색" />

                <InputFieldWrapper
                  ref={inputRef}
                  placeholder={t('Search by hanok name')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = e.target;
                    if (value === '') {
                      setCandidates([]);
                      setSelectedIndex(-1);
                      return;
                    }
                    setCandidates(
                      listings
                        ?.filter((listing) => {
                          const name = listing.name?.toUpperCase() || '';
                          // const address = listing.address?.replace(/south korea/gi, '').trim() || '';
                          const searchValue = value.toUpperCase();
                          return name.includes(searchValue);
                        })
                        .slice(0, 3),
                    );
                    setSelectedIndex(-1);
                  }}
                  onKeyDown={handleKeyDown}
                />
                <>
                  {candidates?.length > 0 && (
                    <CloseSearchButton
                      onClick={() => {
                        setCandidates([]);
                        setSelectedIndex(-1);
                        setShowCandidates(false);
                        setSearchResult(undefined);
                        if (inputRef.current) {
                          inputRef.current.value = '';
                        }
                      }}
                    >
                      <CloseIcon style={{ color: '#fff', width: 15, height: 15 }} />
                    </CloseSearchButton>
                  )}
                </>
              </Row>

              {showCandidates && candidates?.length > 0 && (
                <CandidateWrapper>
                  <GuideText>{t('Press Enter to see all results')}</GuideText>
                  {candidates.map((candidate, index) => (
                    <CandidateItem
                      key={candidate.id}
                      isSelected={index === selectedIndex}
                      onClick={() => {
                        setSearchResult(listings?.filter((listing) => listing.id === candidate.id));
                        setCandidates([]);
                        setSelectedIndex(-1);
                        if (inputRef.current) {
                          inputRef.current.value = candidate.name || '';
                        }
                      }}
                    >
                      <BodyText type="A">
                        {highlightText(candidate.name || '', inputRef.current?.value || '')}
                      </BodyText>
                      <CaptionText type="C" style={{ color: 'var(--secondary-color)' }}>
                        {candidate.address}
                      </CaptionText>
                    </CandidateItem>
                  ))}
                </CandidateWrapper>
              )}
            </>
          )}

          <>
            {isListView ? (
              <ListingList listings={searchResult} showFilter={false} />
            ) : (
              <MapList listings={listings} />
            )}
          </>
        </div>
      </div>
    </PageContainer>
  );
});

const InputFieldWrapper = styled.input`
  font-size: 16px;
  border: none;
  background: none;
  width: 100%;
  font-weight: 400;
  letter-spacing: -0.037px;
  &::placeholder {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const HighlightedText = styled.span`
  background-color: rgba(255, 200, 0, 0.3);
  font-weight: bold;
`;

const CandidateWrapper = styled.div`
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 1200px;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;

  @media ${(props) => props.theme.media.mobile} {
    max-width: calc(100% - 40px);
  }
`;

const GuideText = styled.div`
  text-align: center;
  font-size: 12px;
  color: #888;
  margin-bottom: 4px;
  margin-top: 4px;
`;

const CandidateItem = styled.div<{ isSelected: boolean }>`
  padding: 4px 8px;
  cursor: pointer;
  background-color: ${(props) => (props.isSelected ? '#f8f8f8' : 'transparent')};
  text-align: left;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const CloseSearchButton = styled.div`
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c6c6c6;
  border-radius: 50%;
`;

export default SearchHanokPage;

import useLocaleNavigate from 'hooks/useLocaleNavigate';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Row } from 'saladsoft-primitive';
import {
  GOOGLE_CLIENT_ID,
  GOOGLE_REDIRECT_URI,
  KAKAO_CLIENT_ID,
  KAKAO_REDIRECT_URI,
  ROOT_HOST,
} from 'stores/AppStore';
import styled from 'styled-components';

// 로그인 버튼 클릭 시, 각각 redirect 페이지로 이동
const SocialLoginButtons = ({
  type,
  style,
  forGuestReservation = false,
}: {
  type: 'login' | 'signup';
  style?: React.CSSProperties;
  forGuestReservation?: boolean;
}) => {
  const KAKAOURI = ROOT_HOST! + KAKAO_REDIRECT_URI!;
  const GOOGLEURI = ROOT_HOST! + GOOGLE_REDIRECT_URI!;
  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${KAKAOURI}&response_type=code&state=${type}`;
  const GOOGLE_AUTH_URL = `https://accounts.google.com/o/oauth2/auth?client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${GOOGLEURI}&response_type=code&scope=email%20profile%20openid&access_type=offline&state=${type}`;
  const { t } = useTranslation();
  const navigate = useLocaleNavigate();

  const handleKakaoLogin = () => {
    window.location.href = KAKAO_AUTH_URL;
  };

  const handleGoogleLogin = () => {
    window.location.href = GOOGLE_AUTH_URL;
  };

  return (
    <Container style={style}>
      <LogoContainer
        onClick={() => {
          navigate('/');
        }}
      >
        <Logo src="/images/butlerleelogo.png" alt="butlerlee logo" />
      </LogoContainer>
      {forGuestReservation && (
        <div style={{ fontSize: '15px', fontWeight: '400', color: '#717171', marginTop: '10px' }}>
          Relax in the middle of the city
        </div>
      )}

      <AuthWrapper forGuestReservation={forGuestReservation}>
        {!forGuestReservation && (
          <DividerGroup>
            <Divider />
            <AuthText>{type === 'login' ? t('login') : t('signup')}</AuthText>
            <Divider />
          </DividerGroup>
        )}
        <ButtonGroup>
          <KakaoLoginButton onClick={handleKakaoLogin}>
            <Row gap={24}>
              <img src="/images/kakao-symbol.svg" />
              <div>{type === 'login' ? t('signInWithKakao') : t('signUpWithKakao')}</div>
            </Row>
          </KakaoLoginButton>
          <GoogleLoginButton onClick={handleGoogleLogin}>
            <Row gap={24}>
              <img src="/images/google-symbol.png" />
              <div>{type === 'login' ? t('signInWithGoogle') : t('signUpWithGoogle')}</div>
            </Row>
          </GoogleLoginButton>
        </ButtonGroup>
        <DividerGroup style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
          {type === 'login' ? (
            <>
              <p style={{ marginRight: '5px' }}>{t('noAccount')}</p>
              <Link to="/signup">{t('signup')}</Link>
              <p style={{ marginLeft: '5px' }}>{t('signupDescription')}</p>
            </>
          ) : (
            <>
              <p style={{ marginRight: '5px' }}>{t('alreadyMember')}</p>
              <Link to="/login">{t('login')}</Link>
            </>
          )}
        </DividerGroup>
      </AuthWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  max-width: 588px;
  height: calc(100vh - 200px);
  flex-direction: column;
  font-family: Pretendard, sans-serif;
  text-align: center;
  letter-spacing: -0.04px;
  margin: auto;
  justify-content: center;
`;

const LogoContainer = styled.div`
  width: auto;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const AuthWrapper = styled.div<{ forGuestReservation?: boolean }>`
  display: flex;
  margin-top: ${(props) => (props.forGuestReservation ? '0px' : '64px')};
  width: 100%;
  flex-direction: column;

  @media ${(props) => props.theme.media.mobile} {
    padding: 0 20px;
  }
`;

const DividerGroup = styled.div`
  display: flex;
  align-items: center;
  /* gap: 8px; */
  width: 100%;
  color: var(--Grayscale-50, #8e8e8e);
  font-size: 14px;

  @media ${(props) => props.theme.media.mobile} {
    font-size: 13px;
  }
`;

const Divider = styled.div`
  flex: 1;
  height: 1px;
  background-color: var(--Grayscale-20, #e4e4e4);
`;

const AuthText = styled.div`
  white-space: nowrap;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 32px;
  width: 100%;
`;

const KakaoLoginButton = styled(Button)`
  && {
    background-color: #fee500 !important;
    border-radius: 12px;
    font-size: 15px;
    font-weight: 600;
    color: #000000d9;

    img {
      width: 18px;
      height: 18px;
      object-fit: contain;
    }
  }
`;

const GoogleLoginButton = styled(Button)`
  && {
    font-size: 15px;
    font-weight: 600;
    color: #3c4043 !important;
    border: 1px solid #dadce0;
    background-color: #ffffff !important;

    &:hover {
      background-color: #f1f3f4 !important;
    }

    img {
      width: 18px;
      height: 18px;
      object-fit: contain;
    }
  }
`;

export default SocialLoginButtons;

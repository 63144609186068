import BTButton from 'components/input/BTButton';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, useMediaContext } from 'saladsoft-primitive';
import { LandmarkDto } from 'services/data-contracts';
import styled from 'styled-components';
import { CloseIcon, CopyAddressIcon } from 'components/icons';
import { toast } from 'react-toastify';
import { getGeolocation } from 'libs/helper/mapHelper';
import { GeolocationType } from 'types/CommonTypes';
import NaverMap from 'components/maps/NaverMap';
import { useStoreWrapper } from 'stores/StoreHelper';

const LandmarkModal = ({ landmark, onClick }: { landmark?: LandmarkDto; onClick?: () => void }) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaContext();

  const { uiStore } = useStoreWrapper();
  const [geolocation, setGeolocation] = useState<GeolocationType | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isNaverLoaded, setIsNaverLoaded] = useState(false);

  // 네이버 지도 API 로드 확인
  useEffect(() => {
    const checkNaverMaps = () => {
      try {
        if (window.naver && window.naver.maps && window.naver.maps.Service) {
          setIsNaverLoaded(true);
        } else {
          setTimeout(checkNaverMaps, 500);
        }
      } catch (error) {
        console.error('Error checking Naver Maps:', error);
      }
    };

    checkNaverMaps();
    return () => setIsNaverLoaded(false);
  }, []);

  const getGeolocationInfo = async () => {
    if (!landmark?.address || !isNaverLoaded) return;

    setIsLoading(true);
    try {
      const geoData = await getGeolocation(landmark?.address || '');
      if (geoData && typeof geoData === 'object' && 'x' in geoData && 'y' in geoData) {
        setGeolocation({ latitude: geoData.y as number, longitude: geoData.x as number });
      }
    } catch (error) {
      console.error('Failed to get geolocation:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isNaverLoaded) {
      getGeolocationInfo();
    }
  }, [landmark?.address, isNaverLoaded]);

  return (
    <ModalWrapper>
      {isMobile && (
        <CloseButton onClick={() => uiStore?.universalModal.close()}>
          <CloseIcon />
        </CloseButton>
      )}
      <div onClick={onClick} style={{ padding: '16px' }}>
        {/* Landmark 이미지 */}
        <ImageContainer>
          <img
            src={landmark?.images?.[0]?.url}
            alt={landmark?.name}
            style={{
              width: '100%',
              height: 'auto', // 적절한 높이 설정
              objectFit: 'cover',
              borderRadius: '8px',
            }}
          />
        </ImageContainer>
        <TitleStyled>{landmark?.name}</TitleStyled>
        <AddressStyled>
          <Row width="100%" justifyContent="space-between">
            <div>{landmark?.address}</div>
            <StyledBTButton type="outline" onClick={() => {}}>
              <CopyAddressButton
                onClick={() => {
                  navigator?.clipboard?.writeText(landmark?.address || '');
                  toast.success(t('copyAddress'));
                }}
              >
                <CopyAddressIcon />
                <div>{t('copyClipboard')}</div>
              </CopyAddressButton>
            </StyledBTButton>
          </Row>
        </AddressStyled>
        <DescStyled dangerouslySetInnerHTML={{ __html: landmark?.desc || '' }} />
        {geolocation && (
          <NaverMap geolocations={[geolocation]} title={landmark?.name || ''} mapId="landmarkMap" />
        )}
        <ButtonStyled>
          <BTButton
            type="outline"
            onClick={() => {
              uiStore?.universalModal.close();
            }}
          >
            {t('close')}
          </BTButton>
        </ButtonStyled>
      </div>
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ImageContainer = styled.div`
  @media ${(props) => props.theme.media.mobile} {
    margin-top: 40px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  color: #1d1d1d;

  // 포커스 효과 제거
  outline: none;
  &:focus {
    outline: none;
  }
`;

const CopyAddressButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--gray-gray-90, #1d1d1d);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.03px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const StyledBTButton = styled(BTButton)`
  height: 32px;
  padding: 0 10px;
  border-radius: 4px;
  border: 1px solid var(--gray-gray-30, #d8d8d8);
  background: var(--Grayscale-0, #fff);

  > div {
    padding: 0;
    height: auto;
  }
`;

const TitleStyled = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin-top: 16px;
`;

const AddressStyled = styled.div`
  font-size: 13px;
  color: #888;
`;

const DescStyled = styled.div`
  font-size: 13px;
  color: #888;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ButtonStyled = styled.div`
  margin-top: 20px;
`;

export default LandmarkModal;

import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { supportLanguages } from 'definitions/menus';
import { useMediaContext } from 'saladsoft-primitive';
import { useStoreWrapper } from 'stores/StoreHelper';
import { DividerIcon } from 'components/icons';

const LanguageSelector = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { isMobile } = useMediaContext();
  const { uiStore } = useStoreWrapper();

  const [selectedLanguage, setSelectedLanguage] = useState(supportLanguages[0]);
  const [showMenu, setShowMenu] = useState(false);

  const handleNavigate = (language: { key: string; value: string }) => {
    const currentPath = `${window.location.pathname}`;
    const currentSearch = `${window.location.search}`;
    const pathSegments = currentPath.split('/').filter(Boolean);
    const isLanguageKey = supportLanguages.some((lang) => lang.key === pathSegments[0]);

    setSelectedLanguage(language);
    i18n.changeLanguage(language.key === 'kr' ? 'ko' : language.key);
    localStorage.setItem('i18nextLng', language.key === 'kr' ? 'ko' : language.key);

    let newPath = '';
    if (language.key === 'kr' || language.key === 'ko') {
      if (currentPath === '/' || currentPath === '/kr' || currentPath === '/ko') {
        newPath = '/';
      } else if (isLanguageKey) {
        pathSegments.shift();
        newPath = pathSegments.length ? `/${pathSegments.join('/')}${currentSearch}` : '/';
      } else {
        newPath = `${currentPath}${currentSearch}`;
      }
    } else if (currentPath === '/') {
      newPath = `/${language.key}`;
    } else if (isLanguageKey) {
      pathSegments[0] = language.key;
      newPath = `/${pathSegments.join('/')}${currentSearch}`;
    } else {
      newPath = `/${language.key}${currentPath}${currentSearch}`;
    }

    window.location.href = newPath;
  };

  const handleShowLanguageMenu = () => {
    if (isMobile) {
      uiStore?.bottomSheet.show({
        children: (
          <BottomSheetContainer>
            <HeaderContainer>
              <HeaderTitle>{t('Language Settings')}</HeaderTitle>
              <CloseButton
                onClick={() => {
                  uiStore?.bottomSheet.close();
                }}
              >
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/2e3a6d5039433b65ac03f38e1df15128101fd433cd338d57b88009bb05536059?placeholderIfAbsent=true&apiKey=694b79be46254ffd8018f6be6c4bbc2d"
                  alt="Close"
                />
              </CloseButton>
            </HeaderContainer>
            <BottomSheetContent>
              {supportLanguages.map((language) => (
                <LanguageItem
                  key={language.key}
                  onClick={() => {
                    uiStore?.bottomSheet.close();

                    setTimeout(() => {
                      handleNavigate(language);
                    }, 300);
                  }}
                >
                  <LanguageText>{language.value}</LanguageText>
                  {selectedLanguage.key === language.key && (
                    <SelectedIcon>
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/dcc0251b07d82f5c05a0b9899c2ce28ae05cd346e5d99ccc37303097b9f36beb?placeholderIfAbsent=true&apiKey=694b79be46254ffd8018f6be6c4bbc2d"
                        alt="Selected language indicator"
                      />
                    </SelectedIcon>
                  )}
                </LanguageItem>
              ))}
            </BottomSheetContent>
          </BottomSheetContainer>
        ),
        onConfirmed: (selectedLanguage) => {
          handleNavigate(selectedLanguage);
        },
      });
    } else {
      setShowMenu((prev) => !prev);
    }
  };

  useEffect(() => {
    const current = i18n.language;
    const language = supportLanguages.find((language) => language.key === current);
    if (language) {
      setSelectedLanguage(language);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };

    if (showMenu && !isMobile) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showMenu, isMobile]);

  return (
    <Layout ref={ref} onClick={handleShowLanguageMenu}>
      <img src="/images/text.svg" alt="text" />
      <DividerIcon />
      <TextStyle>{selectedLanguage.value}</TextStyle>
      <img src="/images/downArrow.svg" alt="voice" />
      {showMenu && (
        <Menu>
          {supportLanguages.map((language) => (
            <MenuItem
              key={language.key}
              onClick={() => {
                handleNavigate(language);
                setShowMenu(false);
              }}
            >
              {language.value}
              {selectedLanguage.key === language.key && (
                <SelectedIcon>
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/dcc0251b07d82f5c05a0b9899c2ce28ae05cd346e5d99ccc37303097b9f36beb?placeholderIfAbsent=true&apiKey=694b79be46254ffd8018f6be6c4bbc2d"
                    alt="Selected language indicator"
                  />
                </SelectedIcon>
              )}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Layout>
  );
};

const Layout = styled.div`
  position: relative;
  cursor: pointer;
  display: inline-flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  border: 1px solid var(--Grayscale-20, #e4e4e4);
  background: var(--Grayscale-0, #fff);

  @media ${(props) => props.theme.media.mobile} {
    padding: 8px 12px;
    width: fit-content;
    min-width: max-content;
  }
`;

const TextStyle = styled.div`
  color: var(--Grayscale-80, #2d2d2d);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.03px;
  text-transform: uppercase;
`;

const Menu = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  border-radius: 12px;
  overflow: hidden;
  color: var(--Grayscale-90, #1d1d1d);
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.037px;
  box-shadow:
    0px 2px 12px 0px rgba(0, 0, 0, 0.1),
    0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled.div`
  position: relative;
  align-items: center;
  padding: 16px 20px;
  width: 100%;
  background: #fff;
  white-space: nowrap;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  gap: 35px;

  &:hover {
    background: var(--Grayscale-5, #f8f8f8);
  }
`;

// 모바일 BottomSheet
const BottomSheetContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 24px 20px;
`;

const HeaderTitle = styled.div`
  color: var(--Grayscale-90, #1d1d1d);
  font-family: Pretendard;
  font-size: 19px;
  font-weight: 600;
  line-height: 150%;
  text-align: center;
  font-style: normal;
  letter-spacing: -0.047px;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  outline: none;

  &:focus {
    outline: none;
  }
  img {
    width: 24px;
    height: 24px;
  }
`;

const BottomSheetContent = styled.div`
  padding-bottom: 100px;
`;

const LanguageItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  list-style: none;
  color: var(--gray-gray-90, #1d1d1d);
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.037px;
  cursor: pointer;
`;

const LanguageText = styled.span`
  flex: 1;
`;

const SelectedIcon = styled.span`
  width: 24px;
  height: 24px;
`;

export default LanguageSelector;

import BodyText from 'components/commons/BodyText';
import Section from 'components/layouts/Section';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'saladsoft-primitive';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import BTButton from 'components/input/BTButton';
import { toast } from 'react-toastify';
import { ListingDto } from 'services/data-contracts';
import NaverMap from 'components/maps/NaverMap';
import styled from 'styled-components';

const Location = ({ listing }: { listing?: ListingDto }) => {
  const { t } = useTranslation();

  return (
    <Section title={t('location')} style={{ marginTop: 16 }}>
      <NaverMap
        geolocations={[{ latitude: listing?.latitude ?? 0, longitude: listing?.longitude ?? 0 }]}
        title={listing?.name || ''}
        mapId="locationMap"
      />

      <AddressRow marginTop="16px" alignItems="center" justifyContent="space-between">
        <Row gap={5}>
          <LocationOnOutlinedIcon style={{ color: '#555' }} />
          <BodyText
            type="A"
            style={{ color: '#555', fontSize: '15px', fontWeight: '400', fontFamily: 'Pretendard' }}
          >
            {listing?.address}
          </BodyText>
        </Row>
        <BTButton
          type="outline"
          style={{
            color: '#555',
            height: '32px',
            padding: '0 10px',
            border: '1px solid var(--gray-gray-30, #D8D8D8',
            background: 'var(--Grayscale-0, #FFF)',
            gap: '4px',
            fontFamily: 'Pretendard',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: '500',
          }}
          onClick={() => {
            navigator?.clipboard?.writeText(listing?.address ?? '');
            toast.success(t('copiedToClipboard'));
          }}
        >
          <Row alignItems="center" gap={5}>
            <ContentCopyOutlinedIcon style={{ fontSize: '16px' }} />
            <div style={{ fontSize: '13px', fontWeight: '500' }}>{t('copyClipboard')}</div>
          </Row>
        </BTButton>
      </AddressRow>
    </Section>
  );
};

const MapWrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
`;

const AddressRow = styled(Row)`
  height: 30px;

  @media ${(props) => props.theme.media.mobile} {
    height: auto;
  }
`;

export default Location;

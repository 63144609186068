// import { initializeParamsFromURL } from "@/utils/parameterUtil";
import { useSearchParams } from 'react-router-dom';
import useLocaleNavigate from 'hooks/useLocaleNavigate';

const useParamsHelper = () => {
  const navigate = useLocaleNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const startdate = searchParams.get('startdate');
  const enddate = searchParams.get('enddate');
  const guests = searchParams.get('guests');
  const beddingsets = searchParams.get('beddingsets');
  const city = searchParams.get('city');
  const page = searchParams.get('page');
  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const getParams = () => {
    const idTemp = Number(id);
    const guestsTemp = Number(guests);
    const beddingsetsTemp = Number(beddingsets);
    const pageTemp = Number(page);

    return {
      startdate: startdate ?? "",
      enddate: enddate ?? "",
      guests: !Number.isNaN(guestsTemp) ? guests : "",
      beddingsets: !Number.isNaN(beddingsetsTemp) ? beddingsets : "",
      id: !Number.isNaN(idTemp) ? id : "",
      city: city ?? "",
      page: !Number.isNaN(pageTemp) ? page : "",
    };
  };

  /**
   * @description 현제 url의 파라미터를 업데이트 한다.
   * @param params
   */
  const updateParams = async (params: any) => {
    const updatedParams = new URLSearchParams(searchParams);

    // 새로운 파라미터들을 추가
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        updatedParams.set(key, String(value));
      }
    });

    navigate(`${window.location.pathname}?${updatedParams.toString()}`, {
      replace: true,
    });
  };
    
  /**
   * @description 특정 파라미터를 삭제한다.
   * @param paramKeys
   */
  const deleteParams = async (paramKeys: string | string[]) => {
    const updatedParams = new URLSearchParams(searchParams);

    if (Array.isArray(paramKeys)) {
      paramKeys.forEach((key) => {
        updatedParams.delete(key);
      });
    } else {
      updatedParams.delete(paramKeys);
    }

    navigate(`${window.location.pathname}?${updatedParams.toString()}`, {
      replace: true,
    });
  };

  // useEffect(() => {
  //   /** @description 초기 파라미터 셋팅 */
  //   initializeParamsFromURL();
  // }, [id, startdate, enddate, guests, beddingsets, city]);

  return { updateParams, deleteParams, getParams };
};

export { useParamsHelper };

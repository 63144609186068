import { makeAutoObservable } from 'mobx';
import { AmenityDto, ListingDto } from 'services/data-contracts';

class ListingStore {
  listing?: ListingDto;
  amenities: AmenityDto[];
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setListing(listing: ListingDto) {
    this.listing = listing;
  }

  setAmenities(amenities: AmenityDto[]) {
    this.amenities = amenities;
  }

  setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  unMount() {
    this.listing = undefined;
    this.amenities = [];
    this.isLoading = false;
  }
}

export default ListingStore;

import React, { useEffect, useState, useRef } from 'react';
import { Row, useMediaContext } from 'saladsoft-primitive';
import { BookInfo } from 'types/CommonTypes';
import { motion } from 'framer-motion';
import ListingList from 'components/commons/ListingList';
import PageContainer from 'containers/pc/PageContainer';
import styled, { createGlobalStyle } from 'styled-components';
import useDetectScroll from '@smakss/react-scroll-direction';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ServiceHelper from 'stores/ServiceHelper';
import i18n from 'i18n';
import { useLocation } from 'react-router-dom';
import { HanokSearchField } from 'components/input/HanokSearchField';
import { useStoreWrapper } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import PromotionList from 'components/commons/PromotionList';
import Investor from 'components/commons/Investor';
import { useTranslation } from 'react-i18next';
import { PCDateRangePickerSimple } from 'components/commons/searchBox/PCDateRangePickerSimple';
import CountButtonSimple from 'components/commons/searchBox/CountButtonSimple';
import { getUrlParams } from 'libs/helper/utils';
import { PCSearchBox } from 'components/commons/searchBox/PCSearchBox';
import { MobileSearchBox } from 'components/commons/searchBox/MobileSearchBox';
import { IconButton } from '@mui/material';
import { BulkPriceRequest, ListingDto } from 'services/data-contracts';
import { useScrollRestoration } from 'hooks/useScrollRestoration';

const GlobalStyle = createGlobalStyle`
  .page-content-container {
    padding: 0 !important;
  }
`;

const HomePage = observer(() => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { filterStore, calendarStore } = useStoreWrapper();
  const { isMobile } = useMediaContext();
  const { scrollPosition } = useDetectScroll();
  const { guests } = getUrlParams(['guests']);

  const { navigationTypes } = useScrollRestoration();

  // const { isPop } = usePop(true);

  const location = useLocation();
  const navigate = useLocaleNavigate();

  const [showSearch, setShowSearch] = useState(false);
  const [forceHide, setForceHide] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const targetRef = useRef<HTMLDivElement>(null);

  const handleChangeKeyInput = (value: string) => {
    filterStore.setFilteredListings(
      filterStore.listings?.filter((listing) =>
        listing.name?.toUpperCase().includes(value.toUpperCase()),
      ),
    );
  };

  const fetchListings = () => {
    sessionStorage.removeItem('scroll_page');
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    try {
      ServiceHelper?.listingService?.getListings({ locale: i18n.language }).then((res) => {
        filterStore.setListings(res.data);
        filterStore.setFilteredListings(res.data);
        fetchLowestPrices(res.data);
      });
    } catch (error) {
      console.error('Failed to fetch listings:', error);
    } finally {
      setIsInitialLoading(false);
      filterStore.setLoading(false);
    }
  };

  const fetchLowestPrices = (listings: ListingDto[]) => {
    const ids = listings?.map((listing) => listing.id);

    const bulkPriceRequest: BulkPriceRequest = {
      listingIds: ids as number[],
      startDate: '',
      endDate: '',
      adults: 2,
    };
    ServiceHelper.listingService?.getBulkPrices(bulkPriceRequest).then((res) => {
      filterStore.setLowestPrices(res.data);
    });
  };

  useEffect(() => {
    if (videoRef.current) {
      const playVideo = async () => {
        try {
          await videoRef.current?.play();
        } catch (error) {}
      };
      playVideo();
    }
  }, []);

  useEffect(() => {
    if (forceHide) {
      setShowSearch(false);
    } else if (isMobile) {
      setShowSearch(scrollPosition.top > 550);
    } else {
      setShowSearch(scrollPosition.top > 750);
    }
  }, [scrollPosition, forceHide]);

  useEffect(() => {
    if (filterStore.isFiltered) {
      filterStore.resetFilters();
      fetchListings();
      return;
    }
    if (navigationTypes === null) {
      return;
    }

    const leavePath = sessionStorage.getItem('leavePath');
    // const scrollPage = sessionStorage.getItem('scroll_page');
    // 최초진입
    if (leavePath === null) {
      fetchListings();
      sessionStorage.removeItem('leavePath');
      return;
    }

    const forceFetch = sessionStorage.getItem('forceFetch');
    if (forceFetch !== null) {
      fetchListings();
      sessionStorage.removeItem('forceFetch');
      return;
    }

    if (navigationTypes === 'init') {
      fetchListings();
    } else {
      setIsInitialLoading(false);
    }
  }, [location.search, navigationTypes]);

  useEffect(() => {
    const handleScroll = () => {
      const position = targetRef.current?.getBoundingClientRect();

      if (position) {
        if (position.y < 150) {
          setForceHide(true);
        } else {
          setForceHide(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      sessionStorage.removeItem('forceFetch');
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getVideoHeader = () => {
    return (
      <VideoStyle ref={videoRef} loop autoPlay muted playsInline>
        <source src="/images/web_main.mp4" type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </VideoStyle>
    );
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 100,
        damping: 15,
      },
    },
  };

  return (
    <PageContainer header={getVideoHeader()}>
      <GlobalStyle />
      <div style={{ position: 'relative' }}>
        {!filterStore.isLoading && (
          <>
            {isMobile && (
              <DateAndCountContainer show={showSearch}>
                <PCDateRangePickerSimple dateText={<></>} />
                <div onClick={() => calendarStore.setMobileCalendarShow(true)}>
                  <CountButtonSimple
                    count={Number(guests) || 2}
                    maxCount={12}
                    onChange={() => {}}
                    disabled
                  />
                </div>
              </DateAndCountContainer>
            )}
            <>
              {!isMobile && (
                <NameSearchContainer show={showSearch}>
                  <Row justifyContent="center" alignItems="center" gap={10}>
                    <HanokSearchField
                      isScrolled={showSearch} // 새로운 prop 추가
                      onKeywordChange={(value: string) => {
                        handleChangeKeyInput(value);
                      }}
                    />
                  </Row>
                </NameSearchContainer>
              )}
            </>
          </>
        )}
        <SearchBoxWrapper>
          <>
            {isMobile ? (
              <MobileSearchBox
                dateText={<TextElement />}
                onChange={(bookInfo: BookInfo) => {}}
                onSearch={(bookInfo: BookInfo) => {
                  navigate(
                    `/search?startdate=${bookInfo?.startDate ?? ''}&enddate=${bookInfo?.endDate ?? ''}&guests=${bookInfo?.guests ?? ''}`,
                  );
                }}
              />
            ) : (
              <PCSearchBox
                dateText={<></>}
                onChange={(bookInfo: BookInfo) => {}}
                onSearch={(bookInfo: BookInfo) => {
                  navigate(
                    `/search?startdate=${bookInfo?.startDate ?? ''}&enddate=${bookInfo?.endDate ?? ''}&guests=${bookInfo?.guests ?? ''}`,
                  );
                }}
              />
            )}
          </>
        </SearchBoxWrapper>

        <ListingWrapper
          className="page-container"
          style={{ zIndex: 0, position: 'relative', padding: 0 }}
        >
          {isInitialLoading ? (
            <LoadingContainer>
              <img src="/images/butlerlee-loading.gif" alt="loading" style={{ width: 100 }} />
            </LoadingContainer>
          ) : (
            <>
              <motion.div variants={containerVariants} initial="hidden" animate="visible">
                <ListingList listings={filterStore.filteredListings} itemVariants={itemVariants} />
              </motion.div>
            </>
          )}
        </ListingWrapper>
        <div ref={targetRef}>
          <PromotionList length={3} />
        </div>
        <Investor />
      </div>
    </PageContainer>
  );
});

const DateAndCountContainer = styled.div<{ show: boolean }>`
  position: fixed;
  top: ${(props) => (props.show ? 52 : -100)}px;
  width: 100%;
  z-index: 1;
  background: var(--Grayscale-5, #f8f8f8);
  background: #fff;
  /* transition: ${(props) => (props.show ? 'top 0.5s ease-in-out' : 'none')}; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  gap: 10px;
  padding: 0 16px;
`;

const TextElement = () => {
  const { t } = useTranslation();
  return (
    <Row
      justifyContent="space-between"
      minWidth="300px"
      borderBottom="1px solid var(--font-color)"
      padding="3px 0"
      cursor="pointer"
    >
      <div>{t('Check in / Check out')}</div>
      <IconButton>
        <KeyboardArrowDownIcon />
      </IconButton>
    </Row>
  );
};

const SearchBoxWrapper = styled.div`
  position: absolute;
  top: -60px;
  left: 0;
  width: 100%;
  z-index: 1;

  @media ${(props) => props.theme.media.mobile} {
    position: relative;
    padding: 16px;
  }
`;

const ListingWrapper = styled.div`
  @media ${(props) => props.theme.media.mobile} {
    /* padding: 16px; */
  }
`;

const NameSearchContainer = styled.div<{ show: boolean }>`
  position: fixed;
  top: ${(props) => (props.show ? 128 : -60)}px;
  left: 0;
  width: 100%;
  height: 36px;
  text-align: center;
  z-index: 1;
  opacity: ${(props) => (props.show ? 1 : 0)};
  border-bottom: 1px solid var(--Grayscale-20, #e4e4e4);
  background: var(--Grayscale-5, #f8f8f8);
  transition: opacity 0.5s ease-in-out;
  /* transition: ${(props) => (props.show ? 'top 0.5s ease-in-out' : 'none')}; */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.05);

  @media ${(props) => props.theme.media.mobile} {
    top: ${(props) => (props.show ? 139 : -60)}px;
  }
`;

const VideoStyle = styled.video`
  width: 100%;
  height: 668px;
  object-fit: cover;
  @media ${(props) => props.theme.media.mobile} {
    height: 336px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  width: 100%;
`;

export default HomePage;

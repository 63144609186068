import useLocaleNavigate from 'hooks/useLocaleNavigate';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useStoreWrapper } from 'stores/StoreHelper';
import { GeolocationType } from 'types/CommonTypes';
import { createPortal } from 'react-dom';
import ListingItemForMap from 'components/commons/ListingItemForMap';
import i18n from 'i18n';
// import CustomOverlay from '../../CustomOverlay';

declare global {
  interface Window {
    naver: any;
    CustomOverlay: any;
    jQuery: any;
    $: any;
  }
}
const { naver } = window;

const NaverMapMuilti = observer(
  ({
    geolocations,
    title,
    mapId,
  }: {
    geolocations: GeolocationType[];
    title: string;
    mapId: string;
  }) => {
    let zIndex = 200;
    const { filterStore } = useStoreWrapper();
    const navigate = useLocaleNavigate();
    const [selectedListing, setSelectedListing] = useState<any>(null);
    const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(null);

    const handleListingClick = useCallback(
      (id: number) => {
        navigate(`/listing/${id}`);
      },
      [navigate],
    );

    const bindPriceToMap = () => {
      filterStore.filteredListings?.forEach((listing) => {
        const price = filterStore.lowestPrices.find((price) => price.listingId === listing.id);
        const target = document.getElementsByClassName(
          `map-price-${listing.id}`,
        ) as HTMLCollectionOf<HTMLElement>;
        if (target[0]) {
          target[0].innerHTML = `₩${price?.price?.toLocaleString()}`;
        }
      });
    };

    // 네이버 지도 API 로드 함수
    // const loadNaverMapAPI = () => {
    //   return new Promise<void>((resolve, reject) => {
    //     if (window.naver && window.naver.maps) {
    //       resolve();
    //       return;
    //     }

    //     const script = document.createElement('script');
    //     script.type = 'text/javascript';
    //     script.src = `https://oapi.map.naver.com/openapi/v3/maps.js?ncpClientId=YOUR_CLIENT_ID&language=${i18n.language}`;
    //     script.async = true;
    //     script.onload = () => {
    //       // API 로드 후 약간의 지연 추가
    //       setTimeout(() => {
    //         resolve();
    //       }, 500);
    //     };
    //     script.onerror = (error) => {
    //       reject(new Error('Failed to load Naver Maps API'));
    //     };
    //     document.head.appendChild(script);
    //   });
    // };

    // CustomOverlay 로드 및 초기화 함수
    const loadCustomOverlay = () => {
      return new Promise<void>((resolve, reject) => {
        // 이미 로드되었는지 확인
        if (
          window.CustomOverlay &&
          typeof window.CustomOverlay.prototype.setPosition === 'function'
        ) {
          resolve();
          return;
        }

        // 이미 스크립트가 로드되었지만 초기화가 안된 경우
        const existingScript = document.querySelector('script[src="/customOverlayBox.js"]');
        if (existingScript) {
          // CustomOverlay 초기화 확인
          const checkCustomOverlay = () => {
            if (
              window.CustomOverlay &&
              typeof window.CustomOverlay.prototype.setPosition === 'function'
            ) {
              resolve();
            } else {
              setTimeout(checkCustomOverlay, 500);
            }
          };

          setTimeout(checkCustomOverlay, 500);
          return;
        }

        // 스크립트 로드
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '/customOverlayBox.js';
        script.async = true;
        script.onload = () => {
          // CustomOverlay 초기화 확인
          const checkCustomOverlay = () => {
            if (
              window.CustomOverlay &&
              typeof window.CustomOverlay.prototype.setPosition === 'function'
            ) {
              resolve();
            } else {
              setTimeout(checkCustomOverlay, 500);
            }
          };

          setTimeout(checkCustomOverlay, 500);
        };
        script.onerror = (error) => {
          reject(new Error('Failed to load CustomOverlay script'));
        };
        document.head.appendChild(script);
      });
    };

    const mapRef = useRef<any>(null);
    const overlaysRef = useRef<any[]>([]);

    // 마커 생성 부분 수정
    const createMarkers = () => {
      if (!geolocations || geolocations.length === 0) return;

      // 기존 마커와 오버레이 제거
      overlaysRef.current.forEach((overlay: any) => {
        if (overlay && typeof overlay.setMap === 'function') {
          overlay.setMap(null);
        }
      });
      overlaysRef.current = [];

      // CustomOverlay가 정의되었는지 확인
      if (
        !window.CustomOverlay ||
        typeof window.CustomOverlay.prototype.setPosition !== 'function'
      ) {
        // CustomOverlay 다시 로드 시도
        loadCustomOverlay()
          .then(() => {
            setTimeout(createMarkers, 500);
          })
          .catch((err) => {
            // 오류 처리
          });
        return;
      }

      // 모든 마커의 위치를 포함하는 경계 계산
      const bounds = new naver.maps.LatLngBounds();

      geolocations.forEach((location, index) => {
        try {
          if (!location.latitude || !location.longitude) {
            return;
          }

          const position = new naver.maps.LatLng(location.latitude, location.longitude);

          bounds.extend(position);

          // 커스텀 오버레이 생성 시도
          try {
            // 오버레이 생성 전 필요한 모든 속성 확인
            if (!position) {
              return;
            }

            // CustomOverlay 생성자 호출 전 확인
            if (
              !window.CustomOverlay ||
              typeof window.CustomOverlay.prototype.setPosition !== 'function'
            ) {
              return;
            }
            const overlay = new window.CustomOverlay({
              map: mapRef.current,
              position: position,
              zIndex: index,
              room: {
                listingId: location.listingId,
                listingName: location.listingName || `숙소 ${index + 1}`,
              },
            });

            overlaysRef.current.push(overlay);

            // 오버레이 클릭 이벤트
            setTimeout(() => {
              const element = document.getElementById(`roomId_${location.listingId}`);
              if (element) {
                element.addEventListener('click', () => {
                  if (handleListingClick && location.listingId !== undefined) {
                    handleListingClick(location.listingId);
                  }
                  setSelectedListing(location);
                });
              }
            }, 500);
          } catch (overlayError) {
            // 오류 처리
          }
        } catch (markerError) {
          // 오류 처리
        }
      });

      // 모든 마커가 보이도록 지도 영역 조정
      if (!bounds.isEmpty()) {
        mapRef.current.fitBounds(bounds, {
          top: 100,
          right: 100,
          bottom: 100,
          left: 100,
        });
      }
    };

    useEffect(() => {
      const initializeMap = async () => {
        try {
          // jQuery 로드 확인 (CustomOverlay에 필요)
          if (!window.jQuery && !window.$) {
            await new Promise<void>((resolve, reject) => {
              const script = document.createElement('script');
              script.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
              script.onload = () => resolve();
              script.onerror = () => reject(new Error('Failed to load jQuery'));
              document.head.appendChild(script);
            });
          }

          // 네이버 지도 API 로드
          // await loadNaverMapAPI();

          // CustomOverlay 로드
          await loadCustomOverlay();

          // 지도 초기화
          if (!mapRef.current) {
            const mapContainer = document.getElementById(mapId);
            if (!mapContainer) {
              return;
            }

            const mapOptions = {
              center: new naver.maps.LatLng(37.5666805, 126.9784147),
              zoom: 14,
              zoomControl: true,
              zoomControlOptions: {
                position: naver.maps.Position.TOP_RIGHT,
              },
            };

            mapRef.current = new naver.maps.Map(mapContainer, mapOptions);
          }

          // 마커 생성
          createMarkers();
        } catch (error) {
          // 오류 처리
        }
      };

      initializeMap();

      return () => {
        // 정리 작업
        overlaysRef.current.forEach((overlay: any) => {
          if (overlay && typeof overlay.setMap === 'function') {
            overlay.setMap(null);
          }
        });
      };
    }, [geolocations, handleListingClick]);

    useEffect(() => {
      if (filterStore.filteredListings?.length === filterStore.lowestPrices.length) {
        bindPriceToMap();
      }
    }, [filterStore.lowestPrices]);

    return (
      <>
        <div
          id={mapId}
          style={{
            width: '100%',
            height: 'calc(100vh - 250px)',
            position: 'relative',
          }}
        />
        {portalContainer &&
          selectedListing &&
          createPortal(
            <div
              style={{ width: '100%', height: '100%', zIndex: 1000000, position: 'relative' }}
              onClick={(e) => e.stopPropagation()}
            >
              <ListingItemForMap
                house={selectedListing}
                onClick={handleListingClick}
                onClickClose={() => {
                  setSelectedListing(null);
                }}
              />
            </div>,
            portalContainer,
          )}
      </>
    );
  },
);

export default NaverMapMuilti;

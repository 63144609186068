import { toast } from 'react-toastify';

declare global {
  interface Window {
    naver: any;
  }
}

const { naver } = window;

export const getGeolocation = async (address: string) => {  
  return new Promise((resolve, reject) => {
  // 네이버 지도 객체가 로드되었는지 확인
    if (!naver || !naver.maps || !naver.maps.Service) {
      toast.error('지도 서비스를 불러오는 중입니다. 잠시 후 다시 시도해주세요.');
      reject(new Error('Naver maps not loaded'));
      return;
    }

    naver.maps.Service.geocode(
      {
        address,
      },
      (status: any, response: any) => {
        if (status !== naver.maps.Service.Status.OK) {
          toast.error('지도 취득에 실패했습니다.');
          reject(new Error('Geocoding failed'));
          return;
        }
        const { items } = response.result;
        if (items.length === 0) {
          resolve(null);
          return;
        }
        resolve({
          x: Number(items[0].point.x),
          y: Number(items[0].point.y),
        });
      },
    );
  });
};

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NumberFormat, Row } from 'saladsoft-primitive';
import { ListingDto, TagDto } from 'services/data-contracts';
import ServiceHelper from 'stores/ServiceHelper';
import i18n from 'i18n';
import { useStoreWrapper } from 'stores/StoreHelper';
import BodyText from './BodyText';
import Subtitle from './Subtitle';
import TagList from './TagList';
import FavoriteButton from '../favorite/FavoriteButton';
import { format } from 'react-string-format';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

const ListingItemForMap = ({
  house,
  onClick,
  onClickClose,
  isFavorite,
}: {
  house: ListingDto;
  onClick: (id: number) => void;
  onClickClose: () => void;
  isFavorite?: boolean;
}) => {
  const { filterStore } = useStoreWrapper();
  const { t } = useTranslation();


  const [lowestPrice, setLowestPrice] = useState<number>();

  useEffect(() => {
    setTimeout(() => {
      const lowestPrice = filterStore.lowestPrices.find((price) => price.listingId === house.id);
      if (lowestPrice) {
        setLowestPrice(lowestPrice.price);
      } else {
        ServiceHelper.listingService
          ?.getLowestPriceByListing(house.id as number, { includeActualPrice: true })
          .then((res) => {
            setLowestPrice(res.data?.price || 0);
            filterStore.setLowestPrices([
              ...filterStore.lowestPrices,
              { listingId: house.id as number, price: res.data?.price || 0 },
            ]);
          });
      }
    }, 100);
  }, [house]);

  return (
    <BoxLayout style={{ marginBottom: 'calc(80px - 32px)' }}>
      <div style={{ position: 'relative' }}>
        <PhotoLayout
          image={
            house?.images && house.images.length > 0 ? house.images[0].thumbnailUrl : undefined
          }
        />
        <FavoriteWrapper>
          <FavoriteButton listingId={house.id as number} isFavorite={isFavorite || false} />
        </FavoriteWrapper>
      </div>
      <InfoLayout>
        <div
          style={{ cursor: 'pointer', width: '100%' }}
          onClick={() => {
            onClick(house?.id as number);
          }}
        >
          <Row justifyContent="space-between" width={'100%'}>
            <Subtitle type="A" style={{ marginBottom: 0, color: '#1D1D1D' }}>
              <>{house.name}</>
            </Subtitle>
            <CloseIcon
              onClick={(e) => {
                e.stopPropagation();
                onClickClose();
              }}
              style={{ color: '#1D1D1D', width: 15, height: 15 }}
            />
          </Row>
        </div>
        <BodyText type="I" style={{ color: '#8E8E8E', textAlign: 'left' }}>
          {house.address}
        </BodyText>
        <div style={{ margin: '12px 0' }}>
          <TagList tags={house.tags || []} onClick={(e, tag) => {}} />
        </div>
        <Row gap={6}>
          <BodyText type="F" style={{ color: '#555555' }}>
            {format(
              t('Occupancy'),
              <span style={{ color: '#1C51B6' }}>
                {format(t('StandardGuests'), house.minGuest || 0)}
              </span>,
              <span>{format(t('MaxGuests'), house.maxGuest || 0)}</span>,
            )}
          </BodyText>
          <Row>
            <ParkingInfoWrapper>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="12"
                viewBox="0 0 2 12"
                fill="none"
              >
                <path d="M0.710938 0V12" stroke="#E4E4E4" />
              </svg>
              {house.hasParking ? (
                <img src="/images/parking.svg" alt="no-parking" style={{ marginLeft: '1px' }} />
              ) : (
                <img src="/images/no-parking.svg" alt="parking" style={{ marginLeft: '1px' }} />
              )}
              <BodyText type="F" style={{ color: '#555555' }}>
                {house.hasParking ? t('parkingAvailable') : t('parkingUnavailable')}
              </BodyText>
            </ParkingInfoWrapper>
          </Row>
        </Row>

        <Subtitle type="A" style={{ color: '#1D1D1D' }}>
          <Row marginTop="27px" alignContent="flex-end" alignItems="flex-end">
            <>
              <NumberFormat
                value={lowestPrice as number}
                surffix=""
                style={{
                  display: 'flex',
                  lineHeight: '1',
                  alignItems: 'flex-end',
                  alignContent: 'flex-end',
                  fontWeight: '600',
                }}
              />
              <span
                style={{
                  marginLeft: 4,
                  fontSize: 15,
                  color: '#1D1D1D',
                  fontWeight: '600',
                  letterSpacing: '-0.037px',
                  lineHeight: '1',
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                {t('won')}~
              </span>
              <img src="/images/info.svg" alt="info" />
            </>
          </Row>
        </Subtitle>
      </InfoLayout>
    </BoxLayout>
  );
};

const BoxLayout = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  width: 550px;
`;

const PhotoLayout = styled.div<{ image?: string }>`
  position: relative;
  display: flex;

  width: 180px;
  height: 100%;
  object-fit: cover;
  justify-content: flex-end;
  align-items: center;
  border-radius: 8px 0 0 8px;
  background: url(${(props) => props.image}) lightgray 50% / cover no-repeat;
  margin-bottom: 24px;
`;

const InfoLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 20px;
  flex-grow: 1;
`;

const FavoriteWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const ParkingInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
`;

export default ListingItemForMap;

import styled from 'styled-components';

import moment from 'moment';
import { Popover } from '@mui/material';
import React, { CSSProperties, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParamsHelper } from 'hooks/useParamsHelper';
import { Row, useMediaContext } from 'saladsoft-primitive';
import { useDateRagePicker } from 'hooks/useDateRagePicker';
import BTButton from 'components/input/BTButton';
import { getUrlParam, formatDateRange } from 'libs/helper/utils';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import { useLocation, useParams } from 'react-router-dom';
import BodyText from '../BodyText';
import { DateRangePicker } from './DateRangePicker';
import ClearAllButton from './ClearAllButton';
import { observer } from 'mobx-react';
import { useStoreWrapper } from 'stores/StoreHelper';
import { CountInner } from '../CountInner';

export const PCDateRangePickerSimple = observer(
  ({
    type = 'primary',
    dateText,
    onChange,
    onChangeMonth,
    style,
  }: {
    type?: 'primary' | 'secondary';
    dateText: ReactElement;

    onChange?: (date: any, guests: number) => void;
    onChangeMonth?: (date: Date) => void;
    style?: CSSProperties;
  }) => {
    const navigate = useLocaleNavigate();
    const location = useLocation();

    const { calendarStore, uiStore } = useStoreWrapper();
    const { listingId } = useParams();
    const { t } = useTranslation();
    const { getCloseDate } = useDateRagePicker();
    const { updateParams } = useParamsHelper();
    const { isMobile } = useMediaContext();

    const [selectedDate, setSelectedDate] = useState<any>([null, null]);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const [fromDate, setFromDate] = useState<string>(t('select date'));
    const [guests, setGuests] = useState<number>(2);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
      calendarStore.setCalendarShow(false);
      const temp = getCloseDate(selectedDate);
      if (temp === null) {
        setSelectedDate([null, null]);
      } else {
        onChange?.(temp, guests);
      }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
      const startTemp = getUrlParam('startdate');
      const endTemp = getUrlParam('enddate');
      if (startTemp && endTemp) {
        // setFromDate(startTemp);
        // setToDate(endTemp);
        setSelectedDate([startTemp, endTemp]);

        if (startTemp && endTemp) {
          setFromDate(formatDateRange(startTemp, endTemp, true));
        } else {
          setFromDate(t('select date'));
        }
      }
    }, [location.search]);

    useEffect(() => {
      if (calendarStore.calendarShow) {
        const dateBoxElement = document.querySelector('.DateBoxContainer');
        if (dateBoxElement) {
          setAnchorEl(dateBoxElement as HTMLButtonElement);
        } else {
          console.warn('DateBoxContainer element not found');
        }
      } else {
        setAnchorEl(null);
      }
    }, [calendarStore.calendarShow]);

    useEffect(() => {
      if (location.search) {
        const guests = getUrlParam('guests');
        if (guests) {
          setGuests(parseInt(guests));
        }
      }
    }, [location.search]);

    // useEffect(() => {

    //TGKIM
    //   if (calendarStore.mobileCalendarShow) {
    //     calendarStore.setMobileCalendarShow(true);
    //   }
    // }, [calendarStore.mobileCalendarShow]);

    return (
      <>
        <Row
          {...style}
          gap={10}
          justifyContent="space-evenly"
          alignItems="center"
          width="100%"
          borderRadius={10}
          background="#F8F8F8"
          onClick={(e: any) => {
            if (isMobile) {
              calendarStore.setMobileCalendarShow(true, false, true);
            } else {
              handleClick(e);
            }
          }}
        >
          <DateBoxContainer
            style={{
              width: '100%',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <Row
              gap={10}
              alignItems="center"
              padding="10px 13px"
              maxWidth="230px"
              width={230}
              justifyContent="flex-start"
            >
              <img src="/images/calendar.svg" alt="calendar" />
              <BodyText type="C" style={{ color: '#8E8E8E' }}>
                {fromDate}
              </BodyText>
            </Row>
          </DateBoxContainer>
        </Row>
        <Popover
          id={id}
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <DateContainer>
            <DateInnerContainer>
              <DateRangePicker
                value={selectedDate}
                onChange={(value) => {
                  setSelectedDate(value);
                }}
              />
              <hr style={{ borderTop: '1px solid #F0F0F0' }} />
              <Row justifyContent="space-between">
                <>
                  <CountInnerWrapper
                    count={guests}
                    useConfirm={false}
                    maxCount={12}
                    onChange={(guests: number) => {
                      setGuests(guests);
                      onChange?.(selectedDate, guests);
                    }}
                  />
                </>
                <BTButton
                  style={{ margin: '12px 24px', padding: '0 48px' }}
                  onClick={() => {
                    updateParams({ startdate: '', enddate: '' });

                    // setSelectedDate([null, null]);
                    const formattedStartDate = selectedDate[0]
                      ? moment(selectedDate[0]).format('YYYY-MM-DD')
                      : '';
                    const formattedEndDate = selectedDate[1]
                      ? moment(selectedDate[1]).format('YYYY-MM-DD')
                      : '';

                    if (listingId) {
                      navigate(
                        `/listing/${listingId}?startdate=${formattedStartDate}&enddate=${formattedEndDate}&guests=${guests}`,
                      );
                    } else {
                      navigate(
                        `/search?startdate=${formattedStartDate}&enddate=${formattedEndDate}&guests=${guests}`,
                      );
                    }
                    setAnchorEl(null);
                    calendarStore.setCalendarShow(false);
                  }}
                >
                  {listingId ? t('Apply') : t('Search')}
                </BTButton>
              </Row>
            </DateInnerContainer>
          </DateContainer>
        </Popover>
      </>
    );
  },
);

const DateContainer = styled.div.attrs({
  className: 'MuiPaper-root',
})`
  width: 1000px !important;

  &.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  }
`;

const DateBoxContainer = styled.div.attrs({
  className: 'DateBoxContainer',
})`
  width: 100%;
  justify-content: flex-start;
  border-radius: 10px;
`;

const DateInnerContainer = styled.div`
  margin: 20px;
  background: #fff;
  box-shadow: 0px 0px 22.5px 0px rgba(144, 155, 169, 0.25) !important;
  border-radius: 20px;
`;

const CountInnerWrapper = styled(CountInner)`
  box-shadow: none !important;
  border-bottom: none !important;
  .count-inner-divider {
    display: none;
  }
`;
